import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { TenantsStoreContext, AuthStoreContext, AlertsContext } from '../../state'
import { JarvisTenant, JarvisUpdateTenantRequest } from '../../api/client'
import { UserDrawer } from '../../components/layout'
import { Formik, FormikProps, Field, FieldProps } from 'formik'
import {
  Typography,
  TextField,
  Grid,
  Button,
  Checkbox,
  FormControlLabel,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import path_for from '../path_for'
import { handleError } from '../../utils'

export default withRouter(({ history, match }) => {
  const { state: tenants } = useContext(TenantsStoreContext)
  const { state: session, dispatch: auth } = useContext(AuthStoreContext)
  const { dispatch: showAlert } = useContext(AlertsContext)
  const [tenant, setTenant] = useState<JarvisTenant>({})
  const [isLoading, setIsLoading] = useState(true)
  const id = match.params.id
  const updateRequest: JarvisUpdateTenantRequest = {
    name: tenant && tenant.name,
    enableReactions: tenant && tenant.enableReactions,
    reactionFromEmail: tenant && tenant.reactionFromEmail,
    reactionFromName: tenant && tenant.reactionFromName,
  }

  useEffect(() => {
    if (id === null || id === undefined) {
      return
    }
    tenants.api
      .get(id, session.requestOptions())
      .then(resp => {
        setTenant(resp)
        setIsLoading(false)
      })
      .catch(err => {
        setTenant({})
        handleError(err, showAlert, auth)
      })
  }, [tenants.api, id, showAlert, session, auth])

  const updateTenant = (vaules: JarvisUpdateTenantRequest) => {
    tenants.api
      .update(id, vaules, session.requestOptions())
      .then(resp => {
        showAlert({ type: 'success', message: `Tenant ${id} updated!` })
        setTenant(resp)
      })
      .catch(err => {
        setTenant({})
        handleError(err, showAlert, auth)
      })
  }

  return (
    <UserDrawer
      breadcrumbs={[['Jarvis', path_for.Home()], ['Tenants', path_for.Tenants()], 'Edit']}
    >
      <Formik enableReinitialize initialValues={updateRequest} onSubmit={updateTenant}>
        {(formikBag: FormikProps<JarvisUpdateTenantRequest>) => (
          <form onSubmit={formikBag.handleSubmit}>
            <Card>
              <CardContent>
                <Typography variant="h5">Update tenant's attributes ({id})</Typography>
              </CardContent>
              <CardContent>
                {isLoading ? (
                  <Skeleton variant="rect" height={140} />
                ) : (
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      <Field
                        name="name"
                        render={({ field }: FieldProps<JarvisUpdateTenantRequest>) => (
                          <TextField label="Name" fullWidth {...field} />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="enableReactions"
                        render={({ field }: FieldProps<JarvisUpdateTenantRequest>) => (
                          <FormControlLabel
                            control={<Checkbox {...field} checked={field.value} />}
                            label="Enable Reactions"
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="reactionFromEmail"
                        render={({ field }: FieldProps<JarvisUpdateTenantRequest>) => (
                          <TextField
                            type="email"
                            label="Reaction From (Email)"
                            fullWidth
                            {...field}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        name="reactionFromName"
                        render={({ field }: FieldProps<JarvisUpdateTenantRequest>) => (
                          <TextField label="Reaction From (Name)" fullWidth {...field} />
                        )}
                      />
                    </Grid>
                  </Grid>
                )}
              </CardContent>
              <CardActions>
                {isLoading ? (
                  <Skeleton variant="text" />
                ) : (
                  <Button variant="contained" color="primary" type="submit">
                    Update
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => history.push(path_for.Tenants())}
                >
                  Back to the list
                </Button>
              </CardActions>
            </Card>
          </form>
        )}
      </Formik>
    </UserDrawer>
  )
})
