import React, { createContext, useReducer } from 'react'
import Alert from '../components/alert'
import { JarvisError } from '../utils/handle_error'

type AlertType = 'success' | 'error' | 'warning' | 'info'

type AlertMessage = {
  type: AlertType
  message: string | JarvisError
}

type AlertsState = {
  queue: Array<AlertMessage>
  isEmpty: boolean
}

export type AlertsAction = AlertMessage | { type: 'shift' }

const reducer = (state: AlertsState, action: AlertsAction): AlertsState => {
  switch (action.type) {
    case 'shift':
      const queue = state.queue.slice(1)
      return { ...state, queue: queue, isEmpty: queue.length < 1 }
    default:
      return { ...state, queue: state.queue.concat(action), isEmpty: false }
  }
}

export type AlertsDispatch = {
  state: AlertsState
  dispatch: (action: AlertsAction) => void
}

const AlertsContext = createContext({} as AlertsDispatch)

const AlertsProvider = ({ children }: { children: React.ReactNode[] | React.ReactNode }) => {
  const [state, dispatch] = useReducer(reducer, {
    queue: [],
    isEmpty: true,
  })

  function handleClose() {
    dispatch({ type: 'shift' })
  }

  return (
    <AlertsContext.Provider value={{ state, dispatch }}>
      {!state.isEmpty &&
        (typeof state.queue[0].message === 'string' ? (
          <Alert
            alertType={state.queue[0].type}
            message={state.queue[0].message}
            onClose={handleClose}
          />
        ) : (
          <Alert
            alertType={state.queue[0].type}
            message={(state.queue[0].message as JarvisError).message}
            onClose={handleClose}
          />
        ))}
      {children}
    </AlertsContext.Provider>
  )
}

export { AlertsContext, AlertsProvider }
