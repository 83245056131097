import React, { useContext, useState, useEffect, useCallback } from 'react'
import { withRouter } from 'react-router'
import { UserDrawer } from '../../../components/layout'
import { Typography, Menu, MenuItem } from '@material-ui/core'
import { JarvisUser, FilterOperator } from '../../../api/client'
import { AlertsContext, AuthStoreContext, UsersStoreContext } from '../../../state'
import { PageState, InitialPageState } from '../../../common'
import UsersTable from '../../../components/table/users'
import path_for from '../../path_for'
import { handleError } from '../../../utils'

export default withRouter(({ history, match }) => {
  const { dispatch: showAlert } = useContext(AlertsContext)
  const { state: session, dispatch: auth } = useContext(AuthStoreContext)
  const { state: usersStore } = useContext(UsersStoreContext)
  const [users, setUsers] = useState<JarvisUser[]>([])
  const [total, setTotal] = useState(0)
  const consumerUuid = match.params.uuid
  const [pageState, setPageState] = useState<PageState>({
    ...InitialPageState,
    filter: {
      byColumn: {
        column: 'u.consumer_uuid',
        operator: FilterOperator.EXACT,
        value: consumerUuid,
      },
    },
  })
  const [isLoading, setIsLoading] = useState(true)
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [moreMenuUser, setMoreMenuUser] = useState<JarvisUser | null>(null)
  const [refresh, setRefresh] = useState(0)

  useEffect(() => {
    setIsLoading(true)
    usersStore.api
      .list(
        {
          filter: pageState.filter,
          pageSize: pageState.pageSize,
          pageToken: '' + pageState.pageNumber,
        },
        session.requestOptions()
      )
      .then(response => {
        setIsLoading(false)
        setUsers(response.users!)
        setTotal(response.totalSize!)
      })
      .catch(err => {
        setIsLoading(false)
        setUsers([])
        setTotal(0)
        handleError(err, showAlert, auth)
      })
  }, [
    usersStore.api,
    setTotal,
    showAlert,
    session,
    auth,
    pageState.pageNumber,
    pageState.pageSize,
    pageState.filter,
    refresh,
  ])

  const handlePageStateChange = useCallback(
    newPageState => {
      setPageState(newPageState)
    },
    [setPageState]
  )

  const moreItemClick = (fn: (user: JarvisUser) => void) => {
    return (e: any) => {
      closeMoreMenu()
      if (moreMenuUser === null) {
        return
      }
      fn(moreMenuUser)
    }
  }

  const handleMoreClick = (e: React.MouseEvent<HTMLButtonElement>, user: JarvisUser) => {
    e.preventDefault()
    e.stopPropagation()
    setMoreMenuUser(user)
    setMoreMenuAnchorEl(e.currentTarget)
  }

  const closeMoreMenu = () => {
    setMoreMenuAnchorEl(null)
  }

  const editMembership = (user: JarvisUser) => {
    history.push(path_for.EditMembership(consumerUuid, user.id!))
  }

  const addMembership = (user: JarvisUser) => {
    history.push(path_for.NewMembership(consumerUuid))
  }

  const doRefresh = () => {
    setRefresh(r => r + 1)
  }

  const removeMembership = (user: JarvisUser) => {
    usersStore.api
      .removeSuperuser(
        {
          tenantId: user.tenant && user.tenant.id!,
          userId: user.id!,
          consumerUuid: user.consumerUuid!,
        },
        session.requestOptions()
      )
      .then(() => doRefresh())
      .catch(err => handleError(err, showAlert, auth))
  }

  return (
    <UserDrawer
      breadcrumbs={[['Jarvis', path_for.Home()], ['Users', path_for.Users()], 'Memberships']}
    >
      <Typography variant="h4">Memberships</Typography>
      <UsersTable
        isLoading={isLoading}
        rows={users}
        pageState={pageState}
        total={total}
        setPageState={handlePageStateChange}
        onMoreClick={handleMoreClick}
      />
      <Menu
        id="superuser-more-menu"
        anchorEl={moreMenuAnchorEl}
        keepMounted
        open={Boolean(moreMenuAnchorEl)}
        onClose={closeMoreMenu}
      >
        <MenuItem onClick={moreItemClick(addMembership)}>Add new membership</MenuItem>
        <MenuItem onClick={moreItemClick(editMembership)}>Change this membership</MenuItem>
        <MenuItem onClick={moreItemClick(removeMembership)}>Remove this membership</MenuItem>
      </Menu>
    </UserDrawer>
  )
})
