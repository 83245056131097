import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab'
import EditIcon from '@material-ui/icons/Edit'
import { JarvisTenant } from '../../api/client'
import { fmtDate } from '../../utils'
import { TablePagination } from '@material-ui/core'
import { PageState, AvailableRowsPerPage } from '../../common'
import { useStyles } from './tenants.styles'

type HandleEditFn = (key: string) => void

export default function TenantsTable({
  rows,
  pageState,
  setPageState,
  total,
  onEdit,
}: {
  rows: JarvisTenant[]
  pageState: PageState
  setPageState?: React.Dispatch<React.SetStateAction<PageState>>
  total: number
  onEdit: HandleEditFn
}) {
  const classes = useStyles()

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageState && setPageState({ ...pageState, pageNumber: newPage })
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageState && setPageState({ ...pageState, pageSize: +event.target.value })
  }

  return (
    <Paper className={classes.root}>
      <div>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell align="right">name</TableCell>
              <TableCell align="right">enableReactions</TableCell>
              <TableCell align="right">reactionFromEmail</TableCell>
              <TableCell align="right">reactionFromName</TableCell>
              <TableCell align="right">createdAt</TableCell>
              <TableCell align="right">updatedAt</TableCell>
              <TableCell align="right" />
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="right">{row.name}</TableCell>
                <TableCell align="right">{row.enableReactions ? 'true' : 'false'}</TableCell>
                <TableCell align="right">{row.reactionFromEmail}</TableCell>
                <TableCell align="right">{row.reactionFromName}</TableCell>
                <TableCell align="right">{fmtDate(row.createdAt)}</TableCell>
                <TableCell align="right">{fmtDate(row.updatedAt)}</TableCell>
                <TableCell align="right">
                  <Fab
                    color="secondary"
                    aria-label="edit"
                    size="small"
                    onClick={() => onEdit(row.id!)}
                  >
                    <EditIcon />
                  </Fab>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      {setPageState && (
        <TablePagination
          rowsPerPageOptions={AvailableRowsPerPage}
          component="div"
          count={total}
          rowsPerPage={pageState.pageSize}
          page={pageState.pageNumber}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  )
}
