import React, { useContext, useEffect, useState } from 'react'
import {
  Typography,
  Card,
  CardContent,
  FormControl,
  Select,
  MenuItem,
  Grid,
  Button,
  Box,
} from '@material-ui/core'
import { withRouter } from 'react-router'
import { UserDrawer } from '../../../components/layout'
import {
  AlertsContext,
  AuthStoreContext,
  UsersStoreContext,
  TenantsStoreContext,
} from '../../../state'
import { JarvisConsumer, JarvisTenant } from '../../../api/client'
import { handleError } from '../../../utils'
import { MembershipForm, MembershipFormData } from './form'
import { ConsumersStoreContext } from '../../../state/consumers'
import path_for from '../../path_for'
import { JarvisError } from '../../../utils/handle_error'

export default withRouter(({ history, match }) => {
  const { dispatch: showAlert } = useContext(AlertsContext)
  const { state: session, dispatch: auth } = useContext(AuthStoreContext)
  const { state: usersStore } = useContext(UsersStoreContext)
  const { state: consumersStore } = useContext(ConsumersStoreContext)
  const [consumer, setConsumer] = useState<JarvisConsumer | null>(null)
  const { state: tenantsStore, dispatch: tenantsDispatch } = useContext(TenantsStoreContext)
  const [tenants, setTenants] = useState<JarvisTenant[]>([])
  const [tenant, setTenant] = useState<JarvisTenant | null>(null)
  const [error, setError] = useState<JarvisError | null>(null)

  useEffect(() => {
    if (!match.params.uuid) {
      return
    }
    consumersStore.api
      .get(match.params.uuid, session.requestOptions())
      .then(response => setConsumer(response))
      .catch(err => {
        handleError(err, showAlert, auth).then(() => setConsumer(null))
      })
  }, [
    consumersStore.api,
    setConsumer,
    showAlert,
    auth,
    history,
    match.params.userId,
    match.params.uuid,
    session,
  ])

  useEffect(() => {
    tenantsStore.api
      .list({ pageSize: 1000 }, session.requestOptions())
      .then(response => {
        tenantsDispatch({ type: 'success', tenants: response.tenants!, total: response.totalSize! })
      })
      .catch(err => {
        handleError(err, showAlert, auth).then(je =>
          tenantsDispatch({ type: 'error', error: new Error(je.message) })
        )
      })
  }, [tenantsStore.api, tenantsDispatch, showAlert, auth, session])

  useEffect(() => {
    if (!consumer || tenantsStore.tenants.length < 1) {
      return
    }
    usersStore.api
      .list(
        {
          filter: { byColumn: { column: 'u.consumer_uuid', value: consumer.uuid } },
          pageSize: 1000,
        },
        session.requestOptions()
      )
      .then(resp => {
        const memberIds = resp && resp.users ? resp.users.map(u => u.tenant && u.tenant.id!) : []
        const tnts = tenantsStore.tenants.filter(t => memberIds.indexOf(t.id!) < 0)
        setTenants(tnts)
      })
      .catch(err => {
        handleError(err, showAlert, auth).then(je =>
          tenantsDispatch({ type: 'error', error: new Error(je.message) })
        )
      })
  }, [
    tenantsStore.api,
    tenantsDispatch,
    showAlert,
    auth,
    session,
    consumer,
    usersStore.api,
    tenantsStore.tenants,
  ])

  const handleSubmit = (values: MembershipFormData) => {
    if (!consumer) {
      return
    }

    usersStore.api
      .addSuperuser({ ...values }, session.requestOptions())
      .then(user => {
        showAlert({
          type: 'success',
          message: `Superuser membership was successfully created.`,
        })
        history.push(path_for.Memberships(consumer.uuid!))
      })
      .catch(err => handleError(err, showAlert, auth).then(je => setError(je)))
  }

  const handleTenantChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const tenantId = event.target.value
    const t = tenantsStore.tenants.find(t => t.id === tenantId)
    if (t) setTenant(t)
  }

  return (
    <UserDrawer
      breadcrumbs={[
        ['Jarvis', path_for.Home()],
        ['Users', path_for.Users()],
        ['Memberships', path_for.Memberships(match.params.uuid)],
        'New',
      ]}
    >
      {consumer &&
        (tenants.length < 1 ? (
          <>
            <Typography variant="h4">
              Consumer {consumer.email} is already member of all tenants.
            </Typography>
            <Box p={2} />
            <Button
              onClick={() => history.push(path_for.Memberships(consumer.uuid!))}
              variant="contained"
              color="primary"
            >
              Back to memberships
            </Button>
          </>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">New membership for {consumer.email}</Typography>
              <Card>
                <CardContent>
                  <Typography variant="subtitle1">Select tenant...</Typography>
                  <FormControl>
                    <Select fullWidth value={tenant ? tenant.id : ''} onChange={handleTenantChange}>
                      {tenants.map(t => (
                        <MenuItem key={t.id} value={t.id}>
                          {t.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>
            {tenant && consumer && (
              <Grid item xs={12}>
                <MembershipForm
                  form={{
                    tenantId: tenant.id!,
                    consumerUuid: consumer.uuid!,
                    isRoot: false,
                    roleId: '',
                    folderIds: [],
                  }}
                  onSubmit={handleSubmit}
                  error={error}
                />
              </Grid>
            )}
          </Grid>
        ))}
    </UserDrawer>
  )
})
