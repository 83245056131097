import { UserAddress } from '../../api/client'

export default (field: keyof UserAddress): string =>
  ({
    address: 'Address',
    code: 'Code',
    country: 'Contry',
    locality: 'Locality',
    region: 'Region',
  }[field])
