import React, { useReducer, createContext } from 'react'
import { JarvisRole, RolesApi } from '../api/client/api'

type RolesStoreState = {
  status: string
  api: RolesApi
  roles: JarvisRole[]
  total: number
  error?: Error
}

type RolesStoreAction =
  | { type: 'success'; roles: JarvisRole[]; total: number }
  | { type: 'error'; error: Error }

const reducer = (state: RolesStoreState, action: RolesStoreAction): RolesStoreState => {
  switch (action.type) {
    case 'success':
      return { ...state, status: 'success', roles: action.roles, total: action.total }
    case 'error':
      return { ...state, status: 'error', roles: [], error: action.error }
    default:
      throw new Error()
  }
}

export type RolesStoreDispatch = {
  state: RolesStoreState
  dispatch: (action: RolesStoreAction) => void
}

const RolesStoreContext = createContext({} as RolesStoreDispatch)

const RolesStoreProvider = ({
  baseUrl,
  children,
}: {
  baseUrl: string
  children: React.ReactNode[] | React.ReactNode | Element
}) => {
  const [state, dispatch] = useReducer(reducer, {
    status: 'initial',
    api: new RolesApi({ basePath: baseUrl }),
    roles: [],
    total: 0,
  })
  return (
    <RolesStoreContext.Provider value={{ state, dispatch }}>{children}</RolesStoreContext.Provider>
  )
}

export { RolesStoreContext, RolesStoreProvider }
