import { JarvisErrorDetail } from '../api/client'
import { AlertsAction, AuthStoreAction } from '../state'

export type JarvisError = {
  message: string
  details: JarvisErrorDetail[]
}

type showAlertDispatch = (action: AlertsAction) => void
type authDispatch = (action: AuthStoreAction) => void

export default async function(
  err: Error | Response,
  showAlert?: showAlertDispatch,
  auth?: authDispatch
): Promise<JarvisError> {
  const handle = (je: JarvisError) => {
    if (showAlert !== undefined) {
      showAlert({ type: 'error', message: je.message })
    }
    if (auth !== undefined && err instanceof Response && err.status === 403) {
      auth({ type: 'error', error: je.message })
    }
  }
  switch (err.constructor) {
    case Response:
      const resp = err as Response
      return Promise.resolve(
        await resp
          .json()
          .then(j => {
            const je: JarvisError = {
              message: j.message || j.error,
              details: j.details || [],
            }
            handle(je)
            return je
          })
          .catch(err => {
            const je: JarvisError = { message: String(err), details: [] }
            handle(je)
            return je
          })
      )
    default:
      const je: JarvisError = { message: String(err), details: [] }
      handle(je)
      return Promise.resolve(je)
  }
}
