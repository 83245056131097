import { JarvisFilter } from '../api/client'

export type PageState = {
  pageSize: number
  pageNumber: number
  filter?: JarvisFilter
}

export const InitialPageState = {
  pageNumber: 0,
  pageSize: 10,
}

export const AvailableRowsPerPage = [10, 25, 100]
