import React, { useContext } from 'react'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import { AuthStoreContext } from '../../state'
import { SnackbarOrigin } from '@material-ui/core/Snackbar'
import { Button } from '@material-ui/core'
import { UserDrawer } from '../../components/layout'

export interface State extends SnackbarOrigin {
  open: boolean
}

export default withRouter(({ history }) => {
  const { state: session } = useContext(AuthStoreContext)

  return (
    <UserDrawer>
      <Container maxWidth="sm">
        <Box my={4}>
          <Typography variant="h4" component="h1" gutterBottom>
            Jarvis
          </Typography>
          <p>Welcome {session.userInfo.name!}!</p>
          <Button color="secondary" variant="contained">
            <RouterLink to="/tenants">Tenants</RouterLink>
          </Button>
        </Box>
      </Container>
    </UserDrawer>
  )
})
