/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * apps/jarvis/pb/applications.proto
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: version not set
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "http://localhost".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *  
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name = "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface FilterColumn
 */
export interface FilterColumn {
    /**
     * 
     * @type {string}
     * @memberof FilterColumn
     */
    column?: string;
    /**
     * 
     * @type {FilterOperator}
     * @memberof FilterColumn
     */
    operator?: FilterOperator;
    /**
     * 
     * @type {string}
     * @memberof FilterColumn
     */
    value?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FilterColumn
     */
    inverted?: boolean;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FilterJoinOperator {
    AND = 'AND',
    OR = 'OR'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum FilterOperator {
    EXACT = 'EXACT',
    STARTSWITH = 'STARTS_WITH',
    ENDSWITH = 'ENDS_WITH',
    CONTAINS = 'CONTAINS',
    ISNULL = 'IS_NULL',
    ISNOTNULL = 'IS_NOT_NULL'
}

/**
 * 
 * @export
 * @interface FilterSet
 */
export interface FilterSet {
    /**
     * 
     * @type {Array<JarvisFilter>}
     * @memberof FilterSet
     */
    filters?: Array<JarvisFilter>;
    /**
     * 
     * @type {FilterJoinOperator}
     * @memberof FilterSet
     */
    filtersJoin?: FilterJoinOperator;
    /**
     * 
     * @type {boolean}
     * @memberof FilterSet
     */
    inverted?: boolean;
}

/**
 * 
 * @export
 * @interface JarvisAddSuperuserRequest
 */
export interface JarvisAddSuperuserRequest {
    /**
     * 
     * @type {string}
     * @memberof JarvisAddSuperuserRequest
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisAddSuperuserRequest
     */
    consumerUuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JarvisAddSuperuserRequest
     */
    isRoot?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JarvisAddSuperuserRequest
     */
    roleId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JarvisAddSuperuserRequest
     */
    folderIds?: Array<string>;
}

/**
 * 
 * @export
 * @interface JarvisApplication
 */
export interface JarvisApplication {
    /**
     * 
     * @type {string}
     * @memberof JarvisApplication
     */
    tid?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisApplication
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisApplication
     */
    name?: string;
    /**
     * 
     * @type {JarvisApplicationCore}
     * @memberof JarvisApplication
     */
    core?: JarvisApplicationCore;
    /**
     * 
     * @type {string}
     * @memberof JarvisApplication
     */
    basePath?: string;
    /**
     * 
     * @type {any}
     * @memberof JarvisApplication
     */
    theme?: any;
    /**
     * 
     * @type {Date}
     * @memberof JarvisApplication
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JarvisApplication
     */
    updatedAt?: Date;
}

/**
 * 
 * @export
 * @interface JarvisApplicationCore
 */
export interface JarvisApplicationCore {
    /**
     * 
     * @type {string}
     * @memberof JarvisApplicationCore
     */
    tid?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisApplicationCore
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisApplicationCore
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JarvisApplicationCore
     */
    _public?: boolean;
}

/**
 * 
 * @export
 * @interface JarvisAuthorizeRequest
 */
export interface JarvisAuthorizeRequest {
    /**
     * 
     * @type {string}
     * @memberof JarvisAuthorizeRequest
     */
    provider?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisAuthorizeRequest
     */
    authQuery?: string;
}

/**
 * 
 * @export
 * @interface JarvisConsumer
 */
export interface JarvisConsumer {
    /**
     * 
     * @type {string}
     * @memberof JarvisConsumer
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisConsumer
     */
    email?: string;
    /**
     * 
     * @type {any}
     * @memberof JarvisConsumer
     */
    metadata?: any;
    /**
     * 
     * @type {UserInformation}
     * @memberof JarvisConsumer
     */
    information?: UserInformation;
    /**
     * 
     * @type {string}
     * @memberof JarvisConsumer
     */
    phone?: string;
    /**
     * 
     * @type {Date}
     * @memberof JarvisConsumer
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JarvisConsumer
     */
    updatedAt?: Date;
}

/**
 * 
 * @export
 * @interface JarvisCore
 */
export interface JarvisCore {
    /**
     * 
     * @type {string}
     * @memberof JarvisCore
     */
    tid?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisCore
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisCore
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JarvisCore
     */
    _public?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof JarvisCore
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JarvisCore
     */
    updatedAt?: Date;
}

/**
 * 
 * @export
 * @interface JarvisCreateTenantRequest
 */
export interface JarvisCreateTenantRequest {
    /**
     * 
     * @type {string}
     * @memberof JarvisCreateTenantRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisCreateTenantRequest
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JarvisCreateTenantRequest
     */
    enableReactions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JarvisCreateTenantRequest
     */
    reactionFromEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisCreateTenantRequest
     */
    reactionFromName?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisCreateTenantRequest
     */
    superuserEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisCreateTenantRequest
     */
    fqdn?: string;
    /**
     * 
     * @type {Array<JarvisCreateTenantRequestApp>}
     * @memberof JarvisCreateTenantRequest
     */
    apps?: Array<JarvisCreateTenantRequestApp>;
    /**
     * 
     * @type {string}
     * @memberof JarvisCreateTenantRequest
     */
    defaultBasePath?: string;
}

/**
 * 
 * @export
 * @interface JarvisCreateTenantRequestApp
 */
export interface JarvisCreateTenantRequestApp {
    /**
     * 
     * @type {string}
     * @memberof JarvisCreateTenantRequestApp
     */
    coreId?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisCreateTenantRequestApp
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisCreateTenantRequestApp
     */
    basePath?: string;
}

/**
 * 
 * @export
 * @interface JarvisDomain
 */
export interface JarvisDomain {
    /**
     * 
     * @type {string}
     * @memberof JarvisDomain
     */
    tid?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisDomain
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisDomain
     */
    fqdn?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JarvisDomain
     */
    forceHttps?: boolean;
    /**
     * 
     * @type {JarvisDomainApp}
     * @memberof JarvisDomain
     */
    defaultApp?: JarvisDomainApp;
    /**
     * 
     * @type {any}
     * @memberof JarvisDomain
     */
    theme?: any;
    /**
     * 
     * @type {Date}
     * @memberof JarvisDomain
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JarvisDomain
     */
    updatedAt?: Date;
}

/**
 * 
 * @export
 * @interface JarvisDomainApp
 */
export interface JarvisDomainApp {
    /**
     * 
     * @type {string}
     * @memberof JarvisDomainApp
     */
    tid?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisDomainApp
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisDomainApp
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisDomainApp
     */
    basePath?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisDomainApp
     */
    coreName?: string;
}

/**
 * 
 * @export
 * @interface JarvisDowngradeUserRequest
 */
export interface JarvisDowngradeUserRequest {
    /**
     * 
     * @type {string}
     * @memberof JarvisDowngradeUserRequest
     */
    id?: string;
}

/**
 * 
 * @export
 * @interface JarvisDriver
 */
export interface JarvisDriver {
    /**
     * 
     * @type {string}
     * @memberof JarvisDriver
     */
    tid?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisDriver
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisDriver
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof JarvisDriver
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JarvisDriver
     */
    updatedAt?: Date;
}

/**
 * 
 * @export
 * @interface JarvisErrorDetail
 */
export interface JarvisErrorDetail {
    /**
     * 
     * @type {string}
     * @memberof JarvisErrorDetail
     */
    field?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisErrorDetail
     */
    msg?: string;
}

/**
 * 
 * @export
 * @interface JarvisFilter
 */
export interface JarvisFilter {
    /**
     * 
     * @type {FilterColumn}
     * @memberof JarvisFilter
     */
    byColumn?: FilterColumn;
    /**
     * 
     * @type {FilterSet}
     * @memberof JarvisFilter
     */
    inner?: FilterSet;
}

/**
 * 
 * @export
 * @interface JarvisFolder
 */
export interface JarvisFolder {
    /**
     * 
     * @type {string}
     * @memberof JarvisFolder
     */
    tid?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisFolder
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisFolder
     */
    pid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JarvisFolder
     */
    isRoot?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JarvisFolder
     */
    name?: string;
    /**
     * 
     * @type {any}
     * @memberof JarvisFolder
     */
    theme?: any;
    /**
     * 
     * @type {any}
     * @memberof JarvisFolder
     */
    metadata?: any;
    /**
     * 
     * @type {Array<string>}
     * @memberof JarvisFolder
     */
    tags?: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof JarvisFolder
     */
    information?: any;
    /**
     * 
     * @type {Date}
     * @memberof JarvisFolder
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JarvisFolder
     */
    updatedAt?: Date;
}

/**
 * 
 * @export
 * @interface JarvisListApplicationsResponse
 */
export interface JarvisListApplicationsResponse {
    /**
     * 
     * @type {Array<JarvisApplication>}
     * @memberof JarvisListApplicationsResponse
     */
    applications?: Array<JarvisApplication>;
    /**
     * 
     * @type {string}
     * @memberof JarvisListApplicationsResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof JarvisListApplicationsResponse
     */
    totalSize?: number;
}

/**
 * 
 * @export
 * @interface JarvisListConsumersResponse
 */
export interface JarvisListConsumersResponse {
    /**
     * 
     * @type {Array<JarvisConsumer>}
     * @memberof JarvisListConsumersResponse
     */
    consumers?: Array<JarvisConsumer>;
    /**
     * 
     * @type {string}
     * @memberof JarvisListConsumersResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof JarvisListConsumersResponse
     */
    totalSize?: number;
}

/**
 * 
 * @export
 * @interface JarvisListCoresResponse
 */
export interface JarvisListCoresResponse {
    /**
     * 
     * @type {Array<JarvisCore>}
     * @memberof JarvisListCoresResponse
     */
    cores?: Array<JarvisCore>;
    /**
     * 
     * @type {string}
     * @memberof JarvisListCoresResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof JarvisListCoresResponse
     */
    totalSize?: number;
}

/**
 * 
 * @export
 * @interface JarvisListDomainsResponse
 */
export interface JarvisListDomainsResponse {
    /**
     * 
     * @type {Array<JarvisDomain>}
     * @memberof JarvisListDomainsResponse
     */
    domains?: Array<JarvisDomain>;
    /**
     * 
     * @type {string}
     * @memberof JarvisListDomainsResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof JarvisListDomainsResponse
     */
    totalSize?: number;
}

/**
 * 
 * @export
 * @interface JarvisListDriversResponse
 */
export interface JarvisListDriversResponse {
    /**
     * 
     * @type {Array<JarvisDriver>}
     * @memberof JarvisListDriversResponse
     */
    drivers?: Array<JarvisDriver>;
    /**
     * 
     * @type {string}
     * @memberof JarvisListDriversResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof JarvisListDriversResponse
     */
    totalSize?: number;
}

/**
 * 
 * @export
 * @interface JarvisListFoldersResponse
 */
export interface JarvisListFoldersResponse {
    /**
     * 
     * @type {Array<JarvisFolder>}
     * @memberof JarvisListFoldersResponse
     */
    folders?: Array<JarvisFolder>;
    /**
     * 
     * @type {string}
     * @memberof JarvisListFoldersResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof JarvisListFoldersResponse
     */
    totalSize?: number;
}

/**
 * 
 * @export
 * @interface JarvisListRequest
 */
export interface JarvisListRequest {
    /**
     * 
     * @type {JarvisFilter}
     * @memberof JarvisListRequest
     */
    filter?: JarvisFilter;
    /**
     * 
     * @type {Array<JarvisOrderBy>}
     * @memberof JarvisListRequest
     */
    orderBy?: Array<JarvisOrderBy>;
    /**
     * 
     * @type {number}
     * @memberof JarvisListRequest
     */
    pageSize?: number;
    /**
     * 
     * @type {string}
     * @memberof JarvisListRequest
     */
    pageToken?: string;
}

/**
 * 
 * @export
 * @interface JarvisListRolesResponse
 */
export interface JarvisListRolesResponse {
    /**
     * 
     * @type {Array<JarvisRole>}
     * @memberof JarvisListRolesResponse
     */
    roles?: Array<JarvisRole>;
    /**
     * 
     * @type {string}
     * @memberof JarvisListRolesResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof JarvisListRolesResponse
     */
    totalSize?: number;
}

/**
 * 
 * @export
 * @interface JarvisListTenantsResponse
 */
export interface JarvisListTenantsResponse {
    /**
     * 
     * @type {Array<JarvisTenant>}
     * @memberof JarvisListTenantsResponse
     */
    tenants?: Array<JarvisTenant>;
    /**
     * 
     * @type {string}
     * @memberof JarvisListTenantsResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof JarvisListTenantsResponse
     */
    totalSize?: number;
}

/**
 * 
 * @export
 * @interface JarvisListUsersResponse
 */
export interface JarvisListUsersResponse {
    /**
     * 
     * @type {Array<JarvisUser>}
     * @memberof JarvisListUsersResponse
     */
    users?: Array<JarvisUser>;
    /**
     * 
     * @type {string}
     * @memberof JarvisListUsersResponse
     */
    nextPageToken?: string;
    /**
     * 
     * @type {number}
     * @memberof JarvisListUsersResponse
     */
    totalSize?: number;
}

/**
 * 
 * @export
 * @interface JarvisLoginURLResponse
 */
export interface JarvisLoginURLResponse {
    /**
     * 
     * @type {string}
     * @memberof JarvisLoginURLResponse
     */
    loginURL?: string;
}

/**
 * 
 * @export
 * @interface JarvisOrderBy
 */
export interface JarvisOrderBy {
    /**
     * 
     * @type {string}
     * @memberof JarvisOrderBy
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JarvisOrderBy
     */
    desc?: boolean;
}

/**
 * 
 * @export
 * @interface JarvisRemoveSuperuserRequest
 */
export interface JarvisRemoveSuperuserRequest {
    /**
     * 
     * @type {string}
     * @memberof JarvisRemoveSuperuserRequest
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisRemoveSuperuserRequest
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisRemoveSuperuserRequest
     */
    consumerUuid?: string;
}

/**
 * 
 * @export
 * @interface JarvisRole
 */
export interface JarvisRole {
    /**
     * 
     * @type {string}
     * @memberof JarvisRole
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisRole
     */
    tid?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisRole
     */
    name?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof JarvisRole
     */
    permissions?: Array<number>;
    /**
     * 
     * @type {Date}
     * @memberof JarvisRole
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JarvisRole
     */
    updatedAt?: Date;
}

/**
 * 
 * @export
 * @interface JarvisSystemMessages
 */
export interface JarvisSystemMessages {
    /**
     * 
     * @type {JarvisErrorDetail}
     * @memberof JarvisSystemMessages
     */
    m1?: JarvisErrorDetail;
}

/**
 * 
 * @export
 * @interface JarvisTenant
 */
export interface JarvisTenant {
    /**
     * 
     * @type {string}
     * @memberof JarvisTenant
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisTenant
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JarvisTenant
     */
    enableReactions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JarvisTenant
     */
    reactionFromEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisTenant
     */
    reactionFromName?: string;
    /**
     * 
     * @type {Date}
     * @memberof JarvisTenant
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JarvisTenant
     */
    updatedAt?: Date;
}

/**
 * 
 * @export
 * @interface JarvisUpdateConsumerRequest
 */
export interface JarvisUpdateConsumerRequest {
    /**
     * 
     * @type {string}
     * @memberof JarvisUpdateConsumerRequest
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisUpdateConsumerRequest
     */
    email?: string;
    /**
     * 
     * @type {any}
     * @memberof JarvisUpdateConsumerRequest
     */
    metadata?: any;
    /**
     * 
     * @type {UserInformation}
     * @memberof JarvisUpdateConsumerRequest
     */
    information?: UserInformation;
    /**
     * 
     * @type {string}
     * @memberof JarvisUpdateConsumerRequest
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisUpdateConsumerRequest
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisUpdateConsumerRequest
     */
    passwordConfirmation?: string;
}

/**
 * 
 * @export
 * @interface JarvisUpdateSuperuserRequest
 */
export interface JarvisUpdateSuperuserRequest {
    /**
     * 
     * @type {string}
     * @memberof JarvisUpdateSuperuserRequest
     */
    tenantId?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisUpdateSuperuserRequest
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisUpdateSuperuserRequest
     */
    consumerUuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JarvisUpdateSuperuserRequest
     */
    isRoot?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JarvisUpdateSuperuserRequest
     */
    roleId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JarvisUpdateSuperuserRequest
     */
    folderIds?: Array<string>;
}

/**
 * 
 * @export
 * @interface JarvisUpdateTenantRequest
 */
export interface JarvisUpdateTenantRequest {
    /**
     * 
     * @type {string}
     * @memberof JarvisUpdateTenantRequest
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisUpdateTenantRequest
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JarvisUpdateTenantRequest
     */
    enableReactions?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JarvisUpdateTenantRequest
     */
    reactionFromEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisUpdateTenantRequest
     */
    reactionFromName?: string;
}

/**
 * 
 * @export
 * @interface JarvisUpgradeUserRequest
 */
export interface JarvisUpgradeUserRequest {
    /**
     * 
     * @type {string}
     * @memberof JarvisUpgradeUserRequest
     */
    id?: string;
}

/**
 * 
 * @export
 * @interface JarvisUser
 */
export interface JarvisUser {
    /**
     * 
     * @type {JarvisUserTenant}
     * @memberof JarvisUser
     */
    tenant?: JarvisUserTenant;
    /**
     * 
     * @type {string}
     * @memberof JarvisUser
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisUser
     */
    consumerUuid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JarvisUser
     */
    isRoot?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof JarvisUser
     */
    isSuperuser?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JarvisUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisUser
     */
    phone?: string;
    /**
     * 
     * @type {any}
     * @memberof JarvisUser
     */
    metadata?: any;
    /**
     * 
     * @type {UserInformation}
     * @memberof JarvisUser
     */
    information?: UserInformation;
    /**
     * 
     * @type {any}
     * @memberof JarvisUser
     */
    preferences?: any;
    /**
     * 
     * @type {Array<JarvisUserFolder>}
     * @memberof JarvisUser
     */
    folders?: Array<JarvisUserFolder>;
    /**
     * 
     * @type {JarvisUserRole}
     * @memberof JarvisUser
     */
    role?: JarvisUserRole;
    /**
     * 
     * @type {Date}
     * @memberof JarvisUser
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JarvisUser
     */
    updatedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof JarvisUser
     */
    lastUsedAt?: Date;
}

/**
 * 
 * @export
 * @interface JarvisUserFolder
 */
export interface JarvisUserFolder {
    /**
     * 
     * @type {string}
     * @memberof JarvisUserFolder
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisUserFolder
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface JarvisUserInfo
 */
export interface JarvisUserInfo {
    /**
     * 
     * @type {string}
     * @memberof JarvisUserInfo
     */
    uuid?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisUserInfo
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisUserInfo
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface JarvisUserRole
 */
export interface JarvisUserRole {
    /**
     * 
     * @type {string}
     * @memberof JarvisUserRole
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisUserRole
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface JarvisUserTenant
 */
export interface JarvisUserTenant {
    /**
     * 
     * @type {string}
     * @memberof JarvisUserTenant
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JarvisUserTenant
     */
    name?: string;
}

/**
 * `NullValue` is a singleton enumeration to represent the null value for the `Value` type union.   The JSON representation for `NullValue` is JSON `null`.   - NULL_VALUE: Null value.
 * @export
 * @enum {string}
 */
export enum ProtobufNullValue {
    NULLVALUE = 'NULL_VALUE'
}

/**
 * 
 * @export
 * @interface UserAddress
 */
export interface UserAddress {
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    locality?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    region?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAddress
     */
    country?: string;
}

/**
 * 
 * @export
 * @interface UserInformation
 */
export interface UserInformation {
    /**
     * 
     * @type {string}
     * @memberof UserInformation
     */
    first?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInformation
     */
    last?: string;
    /**
     * 
     * @type {UserAddress}
     * @memberof UserInformation
     */
    address?: UserAddress;
    /**
     * 
     * @type {string}
     * @memberof UserInformation
     */
    timezone?: string;
}


/**
 * ApplicationsApi - fetch parameter creator
 * @export
 */
export const ApplicationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling get.');
            }
            const localVarPath = `/v1/applications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling list.');
            }
            const localVarPath = `/v1/applications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationsApi - functional programming interface
 * @export
 */
export const ApplicationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisApplication> {
            const localVarFetchArgs = ApplicationsApiFetchParamCreator(configuration).get(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisListApplicationsResponse> {
            const localVarFetchArgs = ApplicationsApiFetchParamCreator(configuration).list(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ApplicationsApi - factory interface
 * @export
 */
export const ApplicationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any) {
            return ApplicationsApiFp(configuration).get(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options?: any) {
            return ApplicationsApiFp(configuration).list(body, options)(fetch, basePath);
        },
    };
};

/**
 * ApplicationsApi - object-oriented interface
 * @export
 * @class ApplicationsApi
 * @extends {BaseAPI}
 */
export class ApplicationsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public get(id: string, options?: any) {
        return ApplicationsApiFp(this.configuration).get(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JarvisListRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public list(body: JarvisListRequest, options?: any) {
        return ApplicationsApiFp(this.configuration).list(body, options)(this.fetch, this.basePath);
    }

}

/**
 * AuthApi - fetch parameter creator
 * @export
 */
export const AuthApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JarvisAuthorizeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize(body: JarvisAuthorizeRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling authorize.');
            }
            const localVarPath = `/v1/auth/authorize`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginURL(provider?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/auth/login_url`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (provider !== undefined) {
                localVarQueryParameter['provider'] = provider;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options: any = {}): FetchArgs {
            const localVarPath = `/v1/auth/logout`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me(options: any = {}): FetchArgs {
            const localVarPath = `/v1/auth/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JarvisAuthorizeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize(body: JarvisAuthorizeRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisUserInfo> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authorize(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginURL(provider?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisLoginURLResponse> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).loginURL(provider, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).logout(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisUserInfo> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).me(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {JarvisAuthorizeRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authorize(body: JarvisAuthorizeRequest, options?: any) {
            return AuthApiFp(configuration).authorize(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [provider] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginURL(provider?: string, options?: any) {
            return AuthApiFp(configuration).loginURL(provider, options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options?: any) {
            return AuthApiFp(configuration).logout(options)(fetch, basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        me(options?: any) {
            return AuthApiFp(configuration).me(options)(fetch, basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {JarvisAuthorizeRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authorize(body: JarvisAuthorizeRequest, options?: any) {
        return AuthApiFp(this.configuration).authorize(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [provider] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public loginURL(provider?: string, options?: any) {
        return AuthApiFp(this.configuration).loginURL(provider, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public logout(options?: any) {
        return AuthApiFp(this.configuration).logout(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public me(options?: any) {
        return AuthApiFp(this.configuration).me(options)(this.fetch, this.basePath);
    }

}

/**
 * ConsumersApi - fetch parameter creator
 * @export
 */
export const ConsumersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(uuid: string, options: any = {}): FetchArgs {
            // verify required parameter 'uuid' is not null or undefined
            if (uuid === null || uuid === undefined) {
                throw new RequiredError('uuid','Required parameter uuid was null or undefined when calling get.');
            }
            const localVarPath = `/v1/consumers/get/{uuid}`
                .replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling list.');
            }
            const localVarPath = `/v1/consumers/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JarvisUpdateConsumerRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(body: JarvisUpdateConsumerRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling update.');
            }
            const localVarPath = `/v1/consumers/update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConsumersApi - functional programming interface
 * @export
 */
export const ConsumersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(uuid: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisConsumer> {
            const localVarFetchArgs = ConsumersApiFetchParamCreator(configuration).get(uuid, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisListConsumersResponse> {
            const localVarFetchArgs = ConsumersApiFetchParamCreator(configuration).list(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JarvisUpdateConsumerRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(body: JarvisUpdateConsumerRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisConsumer> {
            const localVarFetchArgs = ConsumersApiFetchParamCreator(configuration).update(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ConsumersApi - factory interface
 * @export
 */
export const ConsumersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} uuid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(uuid: string, options?: any) {
            return ConsumersApiFp(configuration).get(uuid, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options?: any) {
            return ConsumersApiFp(configuration).list(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JarvisUpdateConsumerRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(body: JarvisUpdateConsumerRequest, options?: any) {
            return ConsumersApiFp(configuration).update(body, options)(fetch, basePath);
        },
    };
};

/**
 * ConsumersApi - object-oriented interface
 * @export
 * @class ConsumersApi
 * @extends {BaseAPI}
 */
export class ConsumersApi extends BaseAPI {
    /**
     * 
     * @param {string} uuid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumersApi
     */
    public get(uuid: string, options?: any) {
        return ConsumersApiFp(this.configuration).get(uuid, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JarvisListRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumersApi
     */
    public list(body: JarvisListRequest, options?: any) {
        return ConsumersApiFp(this.configuration).list(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JarvisUpdateConsumerRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsumersApi
     */
    public update(body: JarvisUpdateConsumerRequest, options?: any) {
        return ConsumersApiFp(this.configuration).update(body, options)(this.fetch, this.basePath);
    }

}

/**
 * CoresApi - fetch parameter creator
 * @export
 */
export const CoresApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling get.');
            }
            const localVarPath = `/v1/cores/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling list.');
            }
            const localVarPath = `/v1/cores`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CoresApi - functional programming interface
 * @export
 */
export const CoresApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisCore> {
            const localVarFetchArgs = CoresApiFetchParamCreator(configuration).get(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisListCoresResponse> {
            const localVarFetchArgs = CoresApiFetchParamCreator(configuration).list(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * CoresApi - factory interface
 * @export
 */
export const CoresApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any) {
            return CoresApiFp(configuration).get(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options?: any) {
            return CoresApiFp(configuration).list(body, options)(fetch, basePath);
        },
    };
};

/**
 * CoresApi - object-oriented interface
 * @export
 * @class CoresApi
 * @extends {BaseAPI}
 */
export class CoresApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoresApi
     */
    public get(id: string, options?: any) {
        return CoresApiFp(this.configuration).get(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JarvisListRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoresApi
     */
    public list(body: JarvisListRequest, options?: any) {
        return CoresApiFp(this.configuration).list(body, options)(this.fetch, this.basePath);
    }

}

/**
 * DomainsApi - fetch parameter creator
 * @export
 */
export const DomainsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling get.');
            }
            const localVarPath = `/v1/domains/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling list.');
            }
            const localVarPath = `/v1/domains`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DomainsApi - functional programming interface
 * @export
 */
export const DomainsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisDomain> {
            const localVarFetchArgs = DomainsApiFetchParamCreator(configuration).get(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisListDomainsResponse> {
            const localVarFetchArgs = DomainsApiFetchParamCreator(configuration).list(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DomainsApi - factory interface
 * @export
 */
export const DomainsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any) {
            return DomainsApiFp(configuration).get(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options?: any) {
            return DomainsApiFp(configuration).list(body, options)(fetch, basePath);
        },
    };
};

/**
 * DomainsApi - object-oriented interface
 * @export
 * @class DomainsApi
 * @extends {BaseAPI}
 */
export class DomainsApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainsApi
     */
    public get(id: string, options?: any) {
        return DomainsApiFp(this.configuration).get(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JarvisListRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DomainsApi
     */
    public list(body: JarvisListRequest, options?: any) {
        return DomainsApiFp(this.configuration).list(body, options)(this.fetch, this.basePath);
    }

}

/**
 * DriversApi - fetch parameter creator
 * @export
 */
export const DriversApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling get.');
            }
            const localVarPath = `/v1/drivers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling list.');
            }
            const localVarPath = `/v1/drivers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DriversApi - functional programming interface
 * @export
 */
export const DriversApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisDriver> {
            const localVarFetchArgs = DriversApiFetchParamCreator(configuration).get(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisListDriversResponse> {
            const localVarFetchArgs = DriversApiFetchParamCreator(configuration).list(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DriversApi - factory interface
 * @export
 */
export const DriversApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any) {
            return DriversApiFp(configuration).get(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options?: any) {
            return DriversApiFp(configuration).list(body, options)(fetch, basePath);
        },
    };
};

/**
 * DriversApi - object-oriented interface
 * @export
 * @class DriversApi
 * @extends {BaseAPI}
 */
export class DriversApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriversApi
     */
    public get(id: string, options?: any) {
        return DriversApiFp(this.configuration).get(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JarvisListRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DriversApi
     */
    public list(body: JarvisListRequest, options?: any) {
        return DriversApiFp(this.configuration).list(body, options)(this.fetch, this.basePath);
    }

}

/**
 * FoldersApi - fetch parameter creator
 * @export
 */
export const FoldersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling get.');
            }
            const localVarPath = `/v1/folders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling list.');
            }
            const localVarPath = `/v1/folders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FoldersApi - functional programming interface
 * @export
 */
export const FoldersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisFolder> {
            const localVarFetchArgs = FoldersApiFetchParamCreator(configuration).get(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisListFoldersResponse> {
            const localVarFetchArgs = FoldersApiFetchParamCreator(configuration).list(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * FoldersApi - factory interface
 * @export
 */
export const FoldersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any) {
            return FoldersApiFp(configuration).get(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options?: any) {
            return FoldersApiFp(configuration).list(body, options)(fetch, basePath);
        },
    };
};

/**
 * FoldersApi - object-oriented interface
 * @export
 * @class FoldersApi
 * @extends {BaseAPI}
 */
export class FoldersApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    public get(id: string, options?: any) {
        return FoldersApiFp(this.configuration).get(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JarvisListRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FoldersApi
     */
    public list(body: JarvisListRequest, options?: any) {
        return FoldersApiFp(this.configuration).list(body, options)(this.fetch, this.basePath);
    }

}

/**
 * HealthApi - fetch parameter creator
 * @export
 */
export const HealthApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options: any = {}): FetchArgs {
            const localVarPath = `/v1/health`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = HealthApiFetchParamCreator(configuration).get(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(options?: any) {
            return HealthApiFp(configuration).get(options)(fetch, basePath);
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public get(options?: any) {
        return HealthApiFp(this.configuration).get(options)(this.fetch, this.basePath);
    }

}

/**
 * MessagesApi - fetch parameter creator
 * @export
 */
export const MessagesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemMessagesDummy(options: any = {}): FetchArgs {
            const localVarPath = `/system/messages`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessagesApi - functional programming interface
 * @export
 */
export const MessagesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemMessagesDummy(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisSystemMessages> {
            const localVarFetchArgs = MessagesApiFetchParamCreator(configuration).systemMessagesDummy(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MessagesApi - factory interface
 * @export
 */
export const MessagesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        systemMessagesDummy(options?: any) {
            return MessagesApiFp(configuration).systemMessagesDummy(options)(fetch, basePath);
        },
    };
};

/**
 * MessagesApi - object-oriented interface
 * @export
 * @class MessagesApi
 * @extends {BaseAPI}
 */
export class MessagesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagesApi
     */
    public systemMessagesDummy(options?: any) {
        return MessagesApiFp(this.configuration).systemMessagesDummy(options)(this.fetch, this.basePath);
    }

}

/**
 * RolesApi - fetch parameter creator
 * @export
 */
export const RolesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling get.');
            }
            const localVarPath = `/v1/roles/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling list.');
            }
            const localVarPath = `/v1/roles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RolesApi - functional programming interface
 * @export
 */
export const RolesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisRole> {
            const localVarFetchArgs = RolesApiFetchParamCreator(configuration).get(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisListRolesResponse> {
            const localVarFetchArgs = RolesApiFetchParamCreator(configuration).list(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * RolesApi - factory interface
 * @export
 */
export const RolesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any) {
            return RolesApiFp(configuration).get(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options?: any) {
            return RolesApiFp(configuration).list(body, options)(fetch, basePath);
        },
    };
};

/**
 * RolesApi - object-oriented interface
 * @export
 * @class RolesApi
 * @extends {BaseAPI}
 */
export class RolesApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public get(id: string, options?: any) {
        return RolesApiFp(this.configuration).get(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JarvisListRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RolesApi
     */
    public list(body: JarvisListRequest, options?: any) {
        return RolesApiFp(this.configuration).list(body, options)(this.fetch, this.basePath);
    }

}

/**
 * TenantsApi - fetch parameter creator
 * @export
 */
export const TenantsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JarvisCreateTenantRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(body: JarvisCreateTenantRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling create.');
            }
            const localVarPath = `/v1/tenants/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling get.');
            }
            const localVarPath = `/v1/tenants/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling list.');
            }
            const localVarPath = `/v1/tenants/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {JarvisUpdateTenantRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, body: JarvisUpdateTenantRequest, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling update.');
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling update.');
            }
            const localVarPath = `/v1/tenants/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TenantsApi - functional programming interface
 * @export
 */
export const TenantsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JarvisCreateTenantRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(body: JarvisCreateTenantRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisTenant> {
            const localVarFetchArgs = TenantsApiFetchParamCreator(configuration).create(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisTenant> {
            const localVarFetchArgs = TenantsApiFetchParamCreator(configuration).get(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisListTenantsResponse> {
            const localVarFetchArgs = TenantsApiFetchParamCreator(configuration).list(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {JarvisUpdateTenantRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, body: JarvisUpdateTenantRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisTenant> {
            const localVarFetchArgs = TenantsApiFetchParamCreator(configuration).update(id, body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TenantsApi - factory interface
 * @export
 */
export const TenantsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {JarvisCreateTenantRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(body: JarvisCreateTenantRequest, options?: any) {
            return TenantsApiFp(configuration).create(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any) {
            return TenantsApiFp(configuration).get(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options?: any) {
            return TenantsApiFp(configuration).list(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {JarvisUpdateTenantRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, body: JarvisUpdateTenantRequest, options?: any) {
            return TenantsApiFp(configuration).update(id, body, options)(fetch, basePath);
        },
    };
};

/**
 * TenantsApi - object-oriented interface
 * @export
 * @class TenantsApi
 * @extends {BaseAPI}
 */
export class TenantsApi extends BaseAPI {
    /**
     * 
     * @param {JarvisCreateTenantRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public create(body: JarvisCreateTenantRequest, options?: any) {
        return TenantsApiFp(this.configuration).create(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public get(id: string, options?: any) {
        return TenantsApiFp(this.configuration).get(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JarvisListRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public list(body: JarvisListRequest, options?: any) {
        return TenantsApiFp(this.configuration).list(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {JarvisUpdateTenantRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TenantsApi
     */
    public update(id: string, body: JarvisUpdateTenantRequest, options?: any) {
        return TenantsApiFp(this.configuration).update(id, body, options)(this.fetch, this.basePath);
    }

}

/**
 * UsersApi - fetch parameter creator
 * @export
 */
export const UsersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JarvisAddSuperuserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSuperuser(body: JarvisAddSuperuserRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling addSuperuser.');
            }
            const localVarPath = `/v1/superusers/add`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JarvisDowngradeUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downgrade(body: JarvisDowngradeUserRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling downgrade.');
            }
            const localVarPath = `/v1/users/downgrade`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling get.');
            }
            const localVarPath = `/v1/users/get/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling list.');
            }
            const localVarPath = `/v1/users/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JarvisRemoveSuperuserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSuperuser(body: JarvisRemoveSuperuserRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling removeSuperuser.');
            }
            const localVarPath = `/v1/superusers/remove`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JarvisUpdateSuperuserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSuperuser(body: JarvisUpdateSuperuserRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling updateSuperuser.');
            }
            const localVarPath = `/v1/superusers/update`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {JarvisUpgradeUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upgrade(body: JarvisUpgradeUserRequest, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling upgrade.');
            }
            const localVarPath = `/v1/users/upgrade`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {JarvisAddSuperuserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSuperuser(body: JarvisAddSuperuserRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisUser> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).addSuperuser(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JarvisDowngradeUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downgrade(body: JarvisDowngradeUserRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).downgrade(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisUser> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).get(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisListUsersResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).list(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JarvisRemoveSuperuserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSuperuser(body: JarvisRemoveSuperuserRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).removeSuperuser(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JarvisUpdateSuperuserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSuperuser(body: JarvisUpdateSuperuserRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<JarvisUser> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).updateSuperuser(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {JarvisUpgradeUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upgrade(body: JarvisUpgradeUserRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).upgrade(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
if (options && options.onResponseHeader) options.onResponseHeader(response);
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {JarvisAddSuperuserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addSuperuser(body: JarvisAddSuperuserRequest, options?: any) {
            return UsersApiFp(configuration).addSuperuser(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JarvisDowngradeUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downgrade(body: JarvisDowngradeUserRequest, options?: any) {
            return UsersApiFp(configuration).downgrade(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any) {
            return UsersApiFp(configuration).get(id, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JarvisListRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(body: JarvisListRequest, options?: any) {
            return UsersApiFp(configuration).list(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JarvisRemoveSuperuserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeSuperuser(body: JarvisRemoveSuperuserRequest, options?: any) {
            return UsersApiFp(configuration).removeSuperuser(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JarvisUpdateSuperuserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSuperuser(body: JarvisUpdateSuperuserRequest, options?: any) {
            return UsersApiFp(configuration).updateSuperuser(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {JarvisUpgradeUserRequest} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upgrade(body: JarvisUpgradeUserRequest, options?: any) {
            return UsersApiFp(configuration).upgrade(body, options)(fetch, basePath);
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {JarvisAddSuperuserRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public addSuperuser(body: JarvisAddSuperuserRequest, options?: any) {
        return UsersApiFp(this.configuration).addSuperuser(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JarvisDowngradeUserRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public downgrade(body: JarvisDowngradeUserRequest, options?: any) {
        return UsersApiFp(this.configuration).downgrade(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public get(id: string, options?: any) {
        return UsersApiFp(this.configuration).get(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JarvisListRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public list(body: JarvisListRequest, options?: any) {
        return UsersApiFp(this.configuration).list(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JarvisRemoveSuperuserRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public removeSuperuser(body: JarvisRemoveSuperuserRequest, options?: any) {
        return UsersApiFp(this.configuration).removeSuperuser(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JarvisUpdateSuperuserRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public updateSuperuser(body: JarvisUpdateSuperuserRequest, options?: any) {
        return UsersApiFp(this.configuration).updateSuperuser(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {JarvisUpgradeUserRequest} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public upgrade(body: JarvisUpgradeUserRequest, options?: any) {
        return UsersApiFp(this.configuration).upgrade(body, options)(this.fetch, this.basePath);
    }

}

