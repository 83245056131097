import React, { useState, useEffect, useContext } from 'react'
import { withRouter } from 'react-router'
import { UserDrawer } from '../../components/layout'
import { ConsumerForm, ConsumerFormData } from './form'
import { JarvisError } from '../../utils/handle_error'
import { JarvisConsumer } from '../../api/client'
import Skeleton from '@material-ui/lab/Skeleton'
import { ConsumersStoreContext, AuthStoreContext, AlertsContext } from '../../state'
import { handleError } from '../../utils'
import path_for from '../path_for'

export default withRouter(({ history, match }) => {
  const { state: session, dispatch: auth } = useContext(AuthStoreContext)
  const { state: consumersStore } = useContext(ConsumersStoreContext)
  const { dispatch: showAlert } = useContext(AlertsContext)
  const [formError, setFormError] = useState<JarvisError | null>(null)
  const [consumer, setConsumer] = useState<JarvisConsumer | null>(null)
  const uuid = match.params.uuid

  useEffect(() => {
    if (uuid === null || uuid === undefined) {
      return
    }
    consumersStore.api
      .get(uuid, session.requestOptions())
      .then(resp => {
        setConsumer(resp)
      })
      .catch(err => {
        setConsumer(null)
        handleError(err, showAlert, auth)
      })
  }, [uuid, consumersStore.api, session, showAlert, auth])

  const handleSubmit = (formValues: ConsumerFormData) => {
    consumersStore.api
      .update({ ...formValues }, session.requestOptions())
      .then(resp => {
        showAlert({ type: 'success', message: 'Consumer was successfully updated' })
        history.push(path_for.Users())
      })
      .catch(err => handleError(err, showAlert, auth).then(je => setFormError(je)))
  }

  return (
    <UserDrawer
      breadcrumbs={[['Jarvis', path_for.Home()], ['Users', path_for.Users()], 'Edit consumer']}
    >
      {consumer ? (
        <ConsumerForm
          form={{
            uuid: consumer.uuid!,
            email: consumer.email!,
            information: consumer.information!,
            metadata: consumer.metadata!,
            phone: consumer.phone!,
            createdAt: consumer.createdAt!,
            updatedAt: consumer.updatedAt!,
          }}
          onSubmit={handleSubmit}
          error={formError}
        />
      ) : (
        <Skeleton variant="rect"></Skeleton>
      )}
    </UserDrawer>
  )
})
