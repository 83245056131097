import React from 'react'
import { AlertsProvider } from './alerts'
import { AuthStoreProvider } from './auth'
import { TenantsStoreProvider } from './tenants'
import { UsersStoreProvider } from './users'
import { CoresStoreProvider } from './cores'
import { RolesStoreProvider } from './roles'
import { FoldersStoreProvider } from './folders'
import { ConsumersStoreProvider } from './consumers'

export * from './auth'
export * from './alerts'
export * from './tenants'
export * from './users'
export * from './cores'
export * from './roles'
export * from './folders'
export * from './consumers'

const AllProviders = ({
  baseUrl,
  children,
}: {
  baseUrl: string
  children: React.ReactNode[] | React.ReactNode
}) => {
  return (
    <AlertsProvider>
      <AuthStoreProvider baseUrl={baseUrl}>
        <UsersStoreProvider baseUrl={baseUrl}>
          <CoresStoreProvider baseUrl={baseUrl}>
            <RolesStoreProvider baseUrl={baseUrl}>
              <ConsumersStoreProvider baseUrl={baseUrl}>
                <FoldersStoreProvider baseUrl={baseUrl}>
                  <TenantsStoreProvider baseUrl={baseUrl}>{children}</TenantsStoreProvider>
                </FoldersStoreProvider>
              </ConsumersStoreProvider>
            </RolesStoreProvider>
          </CoresStoreProvider>
        </UsersStoreProvider>
      </AuthStoreProvider>
    </AlertsProvider>
  )
}

export default AllProviders
