import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'

import Checkbox from '@material-ui/core/Checkbox'
import Divider from '@material-ui/core/Divider'
import { useStyles } from './transfer_list.styles'
import { Fab } from '@material-ui/core'

function not(a: string[], b: string[]) {
  return a.filter(value => b.indexOf(value) === -1)
}

function intersection(a: string[], b: string[]) {
  return a.filter(value => b.indexOf(value) !== -1)
}

function union(a: string[], b: string[]) {
  return [...a, ...not(b, a)]
}

const TransferList = ({
  data,
  initial,
  onChange,
}: {
  data: Map<string, string>
  initial: string[]
  onChange: (initial: string[]) => void
}) => {
  const classes = useStyles()
  const [checked, setChecked] = useState<string[]>([])
  const [available, setAvailable] = useState<string[]>([])
  const [selected, setSelected] = useState<string[]>([])

  useEffect(() => {
    setAvailable(not(Array.from(data.keys()), initial))
    setSelected(initial)
  }, [data, initial])

  const availableChecked = intersection(checked, available)
  const selectedChecked = intersection(checked, selected)

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const numberOfChecked = (items: string[]) => intersection(checked, items).length

  const handleToggleAll = (items: string[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const handleCheckedSelected = () => {
    const newSelected = selected.concat(availableChecked)
    setSelected(newSelected)
    setAvailable(not(available, availableChecked))
    setChecked(not(checked, availableChecked))
    onChange(newSelected)
  }

  const handleCheckedAvailable = () => {
    const newSelected = not(selected, selectedChecked)
    setAvailable(available.concat(selectedChecked))
    setSelected(newSelected)
    setChecked(not(checked, selectedChecked))
    onChange(newSelected)
  }

  const customList = (title: React.ReactNode, items: string[]) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value: string) => {
          const labelId = `transfer-list-all-item-${value}-label`

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={data.get(value)} />
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </Card>
  )

  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item xs={5}>
        {customList('Choices', available)}
      </Grid>
      <Grid item xs={2}>
        <Grid container direction="column" alignItems="center">
          <Fab
            size="small"
            className={classes.button}
            onClick={handleCheckedSelected}
            disabled={availableChecked.length === 0}
            aria-label="move selected right"
          >
            <ArrowRightIcon />
          </Fab>
          <Fab
            size="small"
            className={classes.button}
            onClick={handleCheckedAvailable}
            disabled={selectedChecked.length === 0}
            aria-label="move selected left"
          >
            <ArrowLeftIcon />
          </Fab>
        </Grid>
      </Grid>
      <Grid item xs={5}>
        {customList('Chosen', selected)}
      </Grid>
    </Grid>
  )
}

export default TransferList
