import React, { useEffect, useContext, useState } from 'react'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import { Typography, Button, Paper } from '@material-ui/core'
import { AlertsContext, TenantsStoreContext, AuthStoreContext } from '../../state'
import TenantsTable from '../../components/table/tenants'
import { withRouter } from 'react-router'
import { UserDrawer } from '../../components/layout'
import { PageState, InitialPageState } from '../../common'
import path_for from '../path_for'
import { handleError } from '../../utils'

export default withRouter(({ history }) => {
  const { dispatch: showAlert } = useContext(AlertsContext)
  const { state: session, dispatch: auth } = useContext(AuthStoreContext)
  const { state: tenants, dispatch: dispatchTenants } = useContext(TenantsStoreContext)
  const [pageState, setPageState] = useState<PageState>(InitialPageState)

  useEffect(() => {
    tenants.api
      .list(
        {
          pageSize: pageState.pageSize,
          pageToken: '' + pageState.pageNumber,
        },
        session.requestOptions()
      )
      .then(resp => {
        dispatchTenants({ type: 'success', tenants: resp.tenants!, total: resp.totalSize! })
      })
      .catch(err => {
        dispatchTenants({ type: 'error', error: err })
        handleError(err, showAlert, auth)
      })
  }, [
    tenants.api,
    dispatchTenants,
    showAlert,
    session,
    auth,
    pageState.pageNumber,
    pageState.pageSize,
  ])

  const handleTenantEdit = (key: string) => {
    history.push(path_for.Tenants(key))
  }

  const handleNewTenant = () => {
    history.push(path_for.NewTenant())
  }

  return (
    <UserDrawer breadcrumbs={[['Jarvis', path_for.Home()], 'Tenants']}>
      <Container maxWidth="lg">
        <Box my={4}>
          <Paper>
            <Typography variant="h4" component="h1" gutterBottom>
              List of tenants
            </Typography>
            <Button variant="contained" color="primary" onClick={handleNewTenant}>
              Create new tenant
            </Button>
          </Paper>
          <TenantsTable
            rows={tenants.tenants}
            onEdit={handleTenantEdit}
            total={tenants.total}
            pageState={pageState}
            setPageState={setPageState}
          />
        </Box>
      </Container>
    </UserDrawer>
  )
})
