import React from 'react'
import Snackbar, { SnackbarOrigin } from '@material-ui/core/Snackbar'
import clsx from 'clsx'
import { withStyles, WithStyles } from '@material-ui/styles'
import { SnackbarContent, IconButton } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import WarningIcon from '@material-ui/icons/Warning'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import CloseIcon from '@material-ui/icons/Close'
import InfoIcon from '@material-ui/icons/Info'

import styles from './alert.styles'

const alertIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

type AlertType = keyof typeof alertIcon

interface State extends SnackbarOrigin {
  open: boolean
}

interface Props extends WithStyles<typeof styles> {
  alertType: AlertType
  message: string
  onClose: () => void
}

const Alert = ({ alertType, message, onClose, classes }: Props) => {
  const vertical = 'top'
  const horizontal = 'center'

  const Icon = alertIcon[alertType]

  return (
    <Snackbar
      open={true}
      anchorOrigin={{ vertical, horizontal }}
      key={`${vertical},${horizontal}`}
      onClose={onClose}
      autoHideDuration={5000}
      ContentProps={{
        'aria-describedby': 'client-snackbar',
      }}
    >
      <SnackbarContent
        className={classes[alertType]}
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {String(message)}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  )
}

export default withStyles(styles)(Alert)
