import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import { AlertsContext, AuthStoreContext, UsersStoreContext } from '../../state'
import { UserDrawer } from '../../components/layout'
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Divider,
} from '@material-ui/core'
import { JarvisListRequest, FilterOperator, JarvisUser, FilterJoinOperator } from '../../api/client'
import path_for from '../path_for'
import { handleError } from '../../utils'

export default withRouter(({ history, match }) => {
  const { dispatch: showAlert } = useContext(AlertsContext)
  const { state: session, dispatch: auth } = useContext(AuthStoreContext)
  const { state: users } = useContext(UsersStoreContext)
  const [user, setUser] = useState<JarvisUser | undefined>(undefined)
  const [otherUsers, setOtherUsers] = useState<JarvisUser[]>([])

  useEffect(() => {
    if (match.params.id === null || match.params.id === undefined) {
      setUser(undefined)
      return
    }
    users.api
      .get(match.params.id, session.requestOptions())
      .then(response => {
        setUser(response)
      })
      .catch(err => {
        setUser(undefined)
        handleError(err, showAlert, auth)
      })
  }, [users.api, showAlert, setUser, match.params.id, session, auth])

  useEffect(() => {
    if (user === undefined) {
      setOtherUsers([])
      return
    }
    const req: JarvisListRequest = {
      filter: {
        inner: {
          filters: [
            {
              byColumn: {
                column: 'u.email',
                operator: FilterOperator.EXACT,
                value: user!.email!,
              },
            },
            {
              byColumn: {
                column: 'u.id',
                operator: FilterOperator.EXACT,
                value: user!.id!,
                inverted: true,
              },
            },
          ],
          filtersJoin: FilterJoinOperator.AND,
        },
      },
    }
    users.api
      .list(req, session.requestOptions())
      .then(response => {
        setOtherUsers(response.users!)
      })
      .catch(err => {
        setOtherUsers([])
        handleError(err, showAlert, auth)
      })
  }, [users.api, user, setOtherUsers, showAlert, session, auth])

  function doDowngrade() {
    if (user === undefined) {
      showAlert({ type: 'error', message: "Can't do downgrade: user is empty" })
      return
    }
    users.api
      .downgrade({ id: user.id! }, session.requestOptions())
      .then(() => {
        showAlert({
          type: 'success',
          message: 'User ' + user.email! + ' was successfully downgraded to superuser',
        })
        history.push(path_for.Users())
      })
      .catch(err => handleError(err, showAlert, auth))
  }

  return (
    <UserDrawer
      breadcrumbs={[['Jarvis', path_for.Home()], ['Users', path_for.Users()], 'Downgrade']}
    >
      {user ? (
        <Container maxWidth="lg">
          <Card>
            <CardContent>
              <Typography variant="h5">Downgrade superuser to regular user for</Typography>
              <ul>
                <li>Tenant: {user.tenant!.name}</li>
                <li>Email: {user.email}</li>
                <li>IsRoot: {user.isRoot ? 'yes' : 'no'}</li>
              </ul>

              {otherUsers.length > 0 ? (
                <>
                  <Typography variant="body2">Other users with the same email:</Typography>
                  <ul>
                    {otherUsers.map(u => (
                      <li key={u.id!}>
                        {u.tenant!.name} / {u.isRoot ? 'root' : 'non-root'} / {u.role!.name}
                      </li>
                    ))}
                  </ul>
                </>
              ) : (
                <Typography variant="body2">No other users with the same email found.</Typography>
              )}
              <Divider />
              <Typography variant="h6">What will happen?</Typography>
              <Typography variant="body1">
                User {user.email} in tenant {user.tenant!.name} will be downgraded to regular user.
              </Typography>
              <Typography variant="body1">The most recent password will be used.</Typography>
              <Typography variant="body1">
                Users and supersusers with the same email in other tenants will remain unchanged.
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="contained" color="primary" onClick={() => doDowngrade()}>
                Confirm downgrade
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => history.push(path_for.Users())}
              >
                Cancel
              </Button>
            </CardActions>
          </Card>
        </Container>
      ) : (
        <Typography variant="h4">No user found with id: {match.params.id}</Typography>
      )}
    </UserDrawer>
  )
})
