import React, { useContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import {
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  FormControl,
  InputLabel,
  Input,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { UserDrawer } from '../../components/layout'
import { AlertsContext, AuthStoreContext, UsersStoreContext } from '../../state'
import UsersTable from '../../components/table/users'
import { JarvisUser, FilterJoinOperator, FilterOperator } from '../../api/client'
import { UserCard } from '../../components/cards'
import SearchIcon from '@material-ui/icons/Search'
import { PageState, InitialPageState } from '../../common'
import path_for from '../path_for'
import { handleError } from '../../utils'

export default withRouter(({ history }) => {
  const { dispatch: showAlert } = useContext(AlertsContext)
  const { state: session, dispatch: auth } = useContext(AuthStoreContext)
  const { state: users, dispatch: usersDispatch } = useContext(UsersStoreContext)
  const [selectedUser, setSelectedUser] = useState<JarvisUser | null>(null)
  const [refresh, setRefresh] = useState(0)
  const [pageState, setPageState] = useState<PageState>(InitialPageState)
  const [filterVal, setFilterVal] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [moreMenuAnchorEl, setMoreMenuAnchorEl] = useState<HTMLElement | null>(null)
  const [moreMenuUser, setMoreMenuUser] = useState<JarvisUser | null>(null)

  useEffect(() => {
    setIsLoading(true)
    users.api
      .list(
        {
          filter: pageState.filter,
          pageSize: pageState.pageSize,
          pageToken: '' + pageState.pageNumber,
        },
        session.requestOptions()
      )
      .then(response => {
        setIsLoading(false)
        usersDispatch({ type: 'success', users: response.users!, total: response.totalSize! })
      })
      .catch(err => {
        setIsLoading(false)
        usersDispatch({ type: 'error', error: err })
        handleError(err, showAlert, auth)
      })
  }, [
    users.api,
    usersDispatch,
    showAlert,
    refresh,
    session,
    auth,
    pageState.pageNumber,
    pageState.pageSize,
    pageState.filter,
  ])

  const handleUserClick = (e: React.MouseEvent<unknown>, user: JarvisUser) => {
    setSelectedUser(user)
  }

  const handleFilterValChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setFilterVal(event.target.value)
  }

  const applyFilter = () => {
    if (filterVal === '') {
      setPageState({ ...pageState, pageNumber: 0, filter: undefined })
      return
    }
    setPageState({
      ...pageState,
      pageNumber: 0,
      filter: {
        inner: {
          filters: [
            {
              byColumn: {
                column: 'u.email',
                operator: FilterOperator.CONTAINS,
                value: filterVal,
              },
            },
          ],
          filtersJoin: FilterJoinOperator.OR,
        },
      },
    })
  }

  const doRefresh = () => {
    setSelectedUser(null)
    setRefresh(r => r + 1)
  }

  const upgrade = (user: JarvisUser) => {
    history.push(path_for.UpgradeUser(user.id!))
  }

  const downgrade = (user: JarvisUser) => {
    history.push(path_for.DowngradeUser(user.id!))
  }

  const editConsumer = (user: JarvisUser) => {
    if (user.isSuperuser) {
      history.push(path_for.EditConsumers(user.consumerUuid!))
    }
  }

  const manageMemberships = (user: JarvisUser) => {
    if (!user.isSuperuser) {
      showAlert({ type: 'warning', message: 'Jarvis can manage memeberships only for superusers.' })
      return
    }
    history.push(path_for.Memberships(user.consumerUuid!))
  }

  const moreItemClick = (fn: (user: JarvisUser) => void) => {
    return (e: any) => {
      closeMoreMenu()
      if (moreMenuUser === null) {
        return
      }
      fn(moreMenuUser)
    }
  }

  const handleMoreClick = (e: React.MouseEvent<HTMLButtonElement>, user: JarvisUser) => {
    e.preventDefault()
    e.stopPropagation()
    setMoreMenuUser(user)
    setMoreMenuAnchorEl(e.currentTarget)
  }

  const closeMoreMenu = () => {
    setMoreMenuAnchorEl(null)
  }

  return (
    <UserDrawer breadcrumbs={[['Jarvis', path_for.Home()], 'Users']}>
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          <Grid item lg={4}>
            <Card>
              <CardContent>
                <Typography variant="h4" component="h1" gutterBottom>
                  Users
                </Typography>
                <Typography variant="body1">Select user to see possible actions.</Typography>
                <FormControl>
                  <InputLabel htmlFor="component-simple">Filter</InputLabel>
                  <Input id="component-simple" value={filterVal} onChange={handleFilterValChange} />
                </FormControl>
                <IconButton aria-label="search" onClick={applyFilter}>
                  <SearchIcon />
                </IconButton>
              </CardContent>
              <CardActions>
                <Button variant="contained" onClick={doRefresh}>
                  <RefreshIcon />
                  Refresh
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item lg={8}>
            {selectedUser && (
              <UserCard user={selectedUser} onUpgrade={upgrade} onDowngrade={downgrade} />
            )}
          </Grid>
          <Grid item lg={12}>
            <UsersTable
              rows={users.users}
              total={users.total}
              onClick={handleUserClick}
              selectedUser={selectedUser || undefined}
              pageState={pageState}
              setPageState={setPageState}
              isLoading={isLoading}
              onMoreClick={handleMoreClick}
            />
            <Menu
              id="user-more-menu"
              anchorEl={moreMenuAnchorEl}
              keepMounted
              open={
                Boolean(moreMenuAnchorEl) && moreMenuUser !== null && !moreMenuUser.isSuperuser!
              }
              onClose={closeMoreMenu}
            >
              <MenuItem onClick={moreItemClick(upgrade)}>Upgrade to superuser</MenuItem>
            </Menu>
            <Menu
              id="superuser-more-menu"
              anchorEl={moreMenuAnchorEl}
              keepMounted
              open={Boolean(moreMenuAnchorEl) && moreMenuUser !== null && moreMenuUser.isSuperuser!}
              onClose={closeMoreMenu}
            >
              <MenuItem onClick={moreItemClick(editConsumer)}>Edit consumer</MenuItem>
              <MenuItem onClick={moreItemClick(manageMemberships)}>Manage memberships</MenuItem>
              <MenuItem onClick={moreItemClick(downgrade)}>Downgrade to regular user</MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </Container>
    </UserDrawer>
  )
})
