import PathFor from './path_for'
import Auth from './auth'
import Home from './home'
import Tenants from './tenants'
import Users from './users'
import Consumers from './consumers'

export default {
  Auth,
  Home,
  Tenants,
  Users,
  Consumers,
  PathFor,
}
