import React from 'react'
import { Route, Switch } from 'react-router-dom'
import NotFound from './components/notfound'
import Routes from './routes'
import PrivateRoute from './components/private_router'
import AllProviders from './state'
import path_for from './routes/path_for'

const App: React.FC = () => {
  const apiUrl = '/api'

  return (
    <>
      <AllProviders baseUrl={apiUrl}>
        <Switch>
          <Route exact path={path_for.Auth(':provider')} component={Routes.Auth.Authorize} />
          <Route exact path={path_for.Logout()} component={Routes.Auth.Logout} />
          <PrivateRoute exact path={path_for.Home()} component={Routes.Home} />
          <PrivateRoute exact path={path_for.Users()} component={Routes.Users.List} />
          <PrivateRoute
            exact
            path={path_for.EditConsumers(':uuid')}
            component={Routes.Consumers.Edit}
          />
          <PrivateRoute exact path={path_for.UpgradeUser(':id')} component={Routes.Users.Upgrade} />
          <PrivateRoute
            exact
            path={path_for.DowngradeUser(':id')}
            component={Routes.Users.Downgrade}
          />
          <PrivateRoute
            exact
            path={path_for.Memberships(':uuid')}
            component={Routes.Consumers.Memberships.List}
          />
          <PrivateRoute
            exact
            path={path_for.NewMembership(':uuid')}
            component={Routes.Consumers.Memberships.New}
          />
          <PrivateRoute
            exact
            path={path_for.EditMembership(':uuid', ':userId')}
            component={Routes.Consumers.Memberships.Edit}
          />
          <PrivateRoute exact path={path_for.Tenants()} component={Routes.Tenants.List} />
          <PrivateRoute exact path={path_for.NewTenant()} component={Routes.Tenants.New} />
          <PrivateRoute exact path={path_for.Tenants(':id')} component={Routes.Tenants.Edit} />
          <Route component={NotFound} />
        </Switch>
      </AllProviders>
    </>
  )
}

export default App
