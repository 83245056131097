import React from 'react'
import clsx from 'clsx'
import { useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import HomeIcon from '@material-ui/icons/Home'
import ExitIcon from '@material-ui/icons/ExitToApp'
import BusinessIcon from '@material-ui/icons/Business'
import PersonIcon from '@material-ui/icons/Person'
import useReactRouter from 'use-react-router'
import path_for from '../../routes/path_for'
import { useStyles } from './user_drawer.styles'
import { Link, Breadcrumbs } from '@material-ui/core'

export type BreadcrumbItem = [string, string] | string

export default ({
  breadcrumbs,
  children,
}: {
  breadcrumbs?: BreadcrumbItem[]
  children: React.ReactNode[] | React.ReactNode
}) => {
  const { history } = useReactRouter()

  const classes = useStyles()
  const theme = useTheme()
  const [open, setOpen] = React.useState(true)

  function handleDrawerOpen() {
    setOpen(true)
  }

  function handleDrawerClose() {
    setOpen(false)
  }

  const homeClick = () => {
    history.push(path_for.Home())
  }

  const tenantsClick = () => {
    history.push(path_for.Tenants())
  }

  const usersClick = () => {
    history.push(path_for.Users())
  }

  const logoutClick = () => {
    history.push(path_for.Logout())
  }

  const pushTo = (e: React.MouseEvent<any>, url: string) => {
    e.preventDefault()
    e.stopPropagation()
    history.push(url)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          {breadcrumbs ? (
            <Breadcrumbs className={clsx(classes.navBreadcrumb)}>
              {breadcrumbs.map(bc =>
                typeof bc === 'string' ? (
                  <Typography variant="h6" noWrap key={bc}>
                    {bc}
                  </Typography>
                ) : (
                  <Typography variant="h6" noWrap key={bc[1]}>
                    <Link
                      href={bc[1]}
                      color="inherit"
                      onClick={(e: React.MouseEvent<any>) => pushTo(e, bc[1])}
                    >
                      {bc[0]}
                    </Link>
                  </Typography>
                )
              )}
            </Breadcrumbs>
          ) : (
            <Typography variant="h6" noWrap>
              Jarvis
            </Typography>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <ListItem button key="Home" onClick={homeClick}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>
          <ListItem button key="Tenants" onClick={tenantsClick}>
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Tenants" />
          </ListItem>
          <ListItem button key="Users" onClick={usersClick}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button key="Logout" onClick={logoutClick}>
            <ListItemIcon>
              <ExitIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  )
}
