import React, { useReducer, createContext } from 'react'
import { JarvisFolder, FoldersApi } from '../api/client/api'

type FoldersStoreState = {
  status: string
  api: FoldersApi
  folders: JarvisFolder[]
  total: number
  error?: Error
}

type FoldersStoreAction =
  | { type: 'success'; folders: JarvisFolder[]; total: number }
  | { type: 'error'; error: Error }

const reducer = (state: FoldersStoreState, action: FoldersStoreAction): FoldersStoreState => {
  switch (action.type) {
    case 'success':
      return { ...state, status: 'success', folders: action.folders, total: action.total }
    case 'error':
      return { ...state, status: 'error', folders: [], error: action.error }
    default:
      throw new Error()
  }
}

export type FoldersStoreDispatch = {
  state: FoldersStoreState
  dispatch: (action: FoldersStoreAction) => void
}

const FoldersStoreContext = createContext({} as FoldersStoreDispatch)

const FoldersStoreProvider = ({
  baseUrl,
  children,
}: {
  baseUrl: string
  children: React.ReactNode[] | React.ReactNode | Element
}) => {
  const [state, dispatch] = useReducer(reducer, {
    status: 'initial',
    api: new FoldersApi({ basePath: baseUrl }),
    folders: [],
    total: 0,
  })
  return (
    <FoldersStoreContext.Provider value={{ state, dispatch }}>
      {children}
    </FoldersStoreContext.Provider>
  )
}

export { FoldersStoreContext, FoldersStoreProvider }
