import React, { useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { AlertsContext, AuthStoreContext } from '../../state'
import { handleError } from '../../utils'
import path_for from '../path_for'
import { pageBeforeLogin } from '../../components/private_router'
import { Box, CircularProgress } from '@material-ui/core'

export default withRouter(({ history, match }) => {
  const { dispatch: showAlert } = useContext(AlertsContext)
  const { state: session, dispatch: auth } = useContext(AuthStoreContext)

  useEffect(() => {
    session.api
      .authorize(
        {
          provider: match.params.provider,
          authQuery: history.location.search,
        },
        session.requestOptions()
      )
      .then(response => {
        auth({ type: 'success', userInfo: response })
        showAlert({
          type: 'success',
          message: `Welcome ${response.name!}! You have successfully logged in.`,
        })
        const prevPage = pageBeforeLogin()
        if (prevPage && prevPage !== '') {
          history.push(prevPage)
        } else {
          history.push(path_for.Home())
        }
      })
      .catch(err => {
        handleError(err).then(je => {
          auth({ type: 'error', error: je.message })
          history.push(path_for.Home())
        })
      })
  }, [showAlert, auth, history, match.params.provider, session])

  return (
    <Box p={10}>
      <CircularProgress />
    </Box>
  )
})
