import React, { useReducer, createContext } from 'react'
import { JarvisUser, UsersApi } from '../api/client/api'

type UsersStoreState = {
  status: string
  api: UsersApi
  users: JarvisUser[]
  total: number
  error?: Error
}

type UsersStoreAction =
  | { type: 'success'; users: JarvisUser[]; total: number }
  | { type: 'error'; error: Error }

const reducer = (state: UsersStoreState, action: UsersStoreAction): UsersStoreState => {
  switch (action.type) {
    case 'success':
      return { ...state, status: 'success', users: action.users, total: action.total }
    case 'error':
      return { ...state, status: 'error', users: [], error: action.error }
    default:
      throw new Error()
  }
}

export type UsersStoreDispatch = {
  state: UsersStoreState
  dispatch: (action: UsersStoreAction) => void
}

const UsersStoreContext = createContext({} as UsersStoreDispatch)

const UsersStoreProvider = ({
  baseUrl,
  children,
}: {
  baseUrl: string
  children: React.ReactNode[] | React.ReactNode | Element
}) => {
  const [state, dispatch] = useReducer(reducer, {
    status: 'initial',
    api: new UsersApi({ basePath: baseUrl }),
    users: [],
    total: 0,
  })
  return (
    <UsersStoreContext.Provider value={{ state, dispatch }}>{children}</UsersStoreContext.Provider>
  )
}

export { UsersStoreContext, UsersStoreProvider }
