import React, { useReducer, createContext } from 'react'
import { JarvisCore, CoresApi } from '../api/client/api'

type CoresStoreState = {
  status: string
  api: CoresApi
  cores: JarvisCore[]
  total: number
  error?: Error
}

type CoresStoreAction =
  | { type: 'success'; cores: JarvisCore[]; total: number }
  | { type: 'error'; error: Error }

const reducer = (state: CoresStoreState, action: CoresStoreAction): CoresStoreState => {
  switch (action.type) {
    case 'success':
      return { ...state, status: 'success', cores: action.cores, total: action.total }
    case 'error':
      return { ...state, status: 'error', cores: [], error: action.error }
    default:
      throw new Error()
  }
}

export type CoresStoreDispatch = {
  state: CoresStoreState
  dispatch: (action: CoresStoreAction) => void
}

const CoresStoreContext = createContext({} as CoresStoreDispatch)

const CoresStoreProvider = ({
  baseUrl,
  children,
}: {
  baseUrl: string
  children: React.ReactNode[] | React.ReactNode | Element
}) => {
  const [state, dispatch] = useReducer(reducer, {
    status: 'initial',
    api: new CoresApi({ basePath: baseUrl }),
    cores: [],
    total: 0,
  })
  return (
    <CoresStoreContext.Provider value={{ state, dispatch }}>{children}</CoresStoreContext.Provider>
  )
}

export { CoresStoreContext, CoresStoreProvider }
