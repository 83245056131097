import React from 'react'
import { JarvisUser } from '../../api/client'
import { CardContent, Typography, Card, CardActions, Button } from '@material-ui/core'

type onClickHandler = (user: JarvisUser) => void

export default function UserCard({
  user,
  onUpgrade,
  onDowngrade,
}: {
  user: JarvisUser
  onUpgrade?: onClickHandler
  onDowngrade?: onClickHandler
}) {
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom>{user.isSuperuser ? 'Superuser' : 'Regular user'}</Typography>
        <Typography variant="h5">{user.email}</Typography>
      </CardContent>
      <CardActions>
        {user.isSuperuser ? (
          <Button size="small" onClick={() => onDowngrade && onDowngrade(user)}>
            Downgrade to regular user
          </Button>
        ) : (
          <Button size="small" onClick={() => onUpgrade && onUpgrade(user)}>
            Upgrade to superuser
          </Button>
        )}
      </CardActions>
    </Card>
  )
}
