import React, { useContext, useEffect } from 'react'
import { withRouter } from 'react-router'
import {
  Typography,
  TextField,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { Formik, Field, FieldProps, FormikProps, FieldArray } from 'formik'
import {
  TenantsStoreContext,
  AuthStoreContext,
  CoresStoreContext,
  AlertsContext,
} from '../../state'
import { UserDrawer } from '../../components/layout'
import { JarvisCreateTenantRequest } from '../../api/client'
import path_for from '../path_for'
import { handleError } from '../../utils'

export default withRouter(({ history }) => {
  const { state: cores, dispatch: dispatchCores } = useContext(CoresStoreContext)
  const { state: tenants } = useContext(TenantsStoreContext)
  const { state: session, dispatch: auth } = useContext(AuthStoreContext)
  const { dispatch: showAlert } = useContext(AlertsContext)

  useEffect(() => {
    cores.api
      .list({ pageSize: 1000 }, session.requestOptions())
      .then(resp => {
        dispatchCores({ type: 'success', cores: resp.cores!, total: resp.totalSize! })
      })
      .catch(err => {
        dispatchCores({ type: 'error', error: err })
        handleError(err, showAlert, auth)
      })
  }, [cores.api, dispatchCores, showAlert, session, auth])

  return (
    <UserDrawer breadcrumbs={[['Jarvis', path_for.Home()], ['Tenants', path_for.Tenants()], 'New']}>
      <Formik
        initialValues={
          {
            id: '0',
            name: '',
            fqdn: '',
            reactionFromName: '',
            reactionFromEmail: 'no-reply@meshify.com',
            superuserEmail: '',
            apps: [],
            defaultBasePath: '',
          } as JarvisCreateTenantRequest
        }
        onSubmit={values => {
          tenants.api
            .create(values, session.requestOptions())
            .then(tenant => {
              showAlert({
                type: 'success',
                message: `New tenant with id ${tenant.id} successfully created.`,
              })
              history.push(path_for.Tenants())
            })
            .catch(err => handleError(err, showAlert, auth))
        }}
      >
        {(formikBag: FormikProps<JarvisCreateTenantRequest>) => (
          <>
            <form onSubmit={formikBag.handleSubmit}>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Typography variant="h4">Create new tenant</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    name="id"
                    render={({ field }: FieldProps<JarvisCreateTenantRequest>) => (
                      <TextField label="ID" fullWidth {...field} />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="name"
                    render={({ field }: FieldProps<JarvisCreateTenantRequest>) => (
                      <TextField label="Name" fullWidth {...field} />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="fqdn"
                    render={({ field }: FieldProps<JarvisCreateTenantRequest>) => (
                      <TextField label="FQDN" fullWidth {...field} />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="reactionFromEmail"
                    render={({ field }: FieldProps<JarvisCreateTenantRequest>) => (
                      <TextField type="email" label="Reaction From (Email)" fullWidth {...field} />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="reactionFromName"
                    render={({ field }: FieldProps<JarvisCreateTenantRequest>) => (
                      <TextField label="Reaction From (Name)" fullWidth {...field} />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name="superuserEmail"
                    render={({ field }: FieldProps<JarvisCreateTenantRequest>) => (
                      <TextField
                        type="email"
                        label="Admin email"
                        fullWidth
                        helperText="Should be superuser"
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}></Grid>
                <FieldArray
                  name="apps"
                  render={appsHelpers => (
                    <>
                      <Grid item xs={4}>
                        <Button variant="outlined" onClick={() => appsHelpers.push({})}>
                          Add application
                        </Button>
                      </Grid>
                      <Grid item xs={8}>
                        <Field
                          name="defaultBasePath"
                          render={({ field }: FieldProps<JarvisCreateTenantRequest>) => (
                            <FormControl fullWidth>
                              <InputLabel htmlFor="defaultBasePath">
                                Default basePath for domain
                              </InputLabel>
                              <Select
                                inputProps={{ ...field }}
                                disabled={formikBag.values.apps!.length < 1}
                              >
                                {formikBag.values.apps!.map(app => (
                                  <MenuItem value={app.basePath}>
                                    {app.name} - {app.basePath}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText>TenantID - Core.name</FormHelperText>
                            </FormControl>
                          )}
                        />
                      </Grid>
                      {formikBag.values.apps &&
                        formikBag.values.apps!.map((app, appInd) => (
                          <Grid item xs={12} key={appInd}>
                            <Grid container spacing={5}>
                              <Grid item xs={3}>
                                <Field
                                  name={`apps.${appInd}.coreId`}
                                  render={({ field }: FieldProps<JarvisCreateTenantRequest>) => (
                                    <FormControl fullWidth>
                                      <InputLabel htmlFor="core">Core</InputLabel>
                                      <Select inputProps={{ ...field }}>
                                        {cores.cores!.map(core => (
                                          <MenuItem value={core.id!}>
                                            {core.tid} - {core.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                      <FormHelperText>TenantID - Core.name</FormHelperText>
                                    </FormControl>
                                  )}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Field
                                  name={`apps.${appInd}.name`}
                                  render={({ field }: FieldProps<JarvisCreateTenantRequest>) => (
                                    <TextField label="Applicaton name" fullWidth {...field} />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <Field
                                  name={`apps.${appInd}.basePath`}
                                  render={({ field }: FieldProps<JarvisCreateTenantRequest>) => (
                                    <TextField label="Base path" fullWidth {...field} />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  aria-label="delete"
                                  onClick={() => appsHelpers.remove(appInd)}
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                      <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit">
                          Submit
                        </Button>
                      </Grid>
                    </>
                  )}
                />
              </Grid>
            </form>
          </>
        )}
      </Formik>
    </UserDrawer>
  )
})
