import React from 'react'
import { JarvisError } from '../../utils/handle_error'
import {
  Card,
  CardContent,
  CardActions,
  Button,
  CardHeader,
  Grid,
  TextField,
  Typography,
  IconButton,
} from '@material-ui/core'
import { Formik, Field, FieldProps, FormikProps } from 'formik'
import { UserInformation } from '../../api/client'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import { Labels } from '../../utils'

export type ConsumerFormData = {
  uuid: string
  email: string
  information: UserInformation
  metadata: Object
  phone: string
  createdAt: Date
  updatedAt: Date
  password?: string
  passwordConfirmation?: string
  newMetadataKey?: string
  newMetadataValue?: string
}

export const ConsumerForm = ({
  form,
  onSubmit,
  error,
}: {
  form: ConsumerFormData
  onSubmit: (values: ConsumerFormData) => void
  error: JarvisError | null
}) => {
  const name = () =>
    form.information.first || form.information.last
      ? `${form.information.first} ${form.information.last} <${form.email}>`
      : `<${form.email}>`

  const errorsFor = (field: string): string[] =>
    (error ? error.details : []).filter(d => d.field === field).map(e => e.msg!)

  const errorFor = (field: string): string | null =>
    errorsFor(field).length > 0 ? errorsFor(field).join(', ') : null

  const addMetadataKey = (bag: FormikProps<ConsumerFormData>) => {
    if (bag.values.newMetadataKey === undefined || bag.values.newMetadataKey === '') {
      return
    }
    const metadata: any = { ...bag.values.metadata }
    metadata[bag.values.newMetadataKey!] = bag.values.newMetadataValue
    bag.setValues({ ...bag.values, metadata, newMetadataKey: '', newMetadataValue: '' })
  }

  const removeMetadataKey = (bag: FormikProps<ConsumerFormData>, key: string) => {
    const metadata: any = { ...bag.values.metadata }
    delete metadata[key]
    bag.setValues({ ...bag.values, metadata })
  }

  return (
    <Formik initialValues={form} onSubmit={onSubmit}>
      {formikBag => (
        <form onSubmit={formikBag.handleSubmit}>
          <Card>
            <CardHeader title={name()} />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                  <Field
                    name="email"
                    render={({ field }: FieldProps<ConsumerFormData>) => (
                      <TextField
                        type="email"
                        label={Labels.Consumer('email')}
                        helperText={errorFor('email') || 'should be unique across all tenants'}
                        error={!!errorFor('email')}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="phone"
                    render={({ field }: FieldProps<ConsumerFormData>) => (
                      <TextField
                        type="phone"
                        label={Labels.Consumer('phone')}
                        helperText={errorFor('phone')}
                        error={!!errorFor('phone')}
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="password"
                    render={({ field }: FieldProps<ConsumerFormData>) => (
                      <TextField
                        fullWidth
                        label="Password"
                        type="password"
                        helperText={errorFor('password')}
                        error={!!errorFor('password')}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="passwordConfirmation"
                    render={({ field }: FieldProps<ConsumerFormData>) => (
                      <TextField
                        fullWidth
                        label="Password confirmation"
                        type="password"
                        helperText={errorFor('password_confirmation')}
                        error={!!errorFor('password_confirmation')}
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      title={Labels.Consumer('information')}
                      subheader={errorFor('email')}
                    />
                    <CardContent>
                      {errorFor('email') && (
                        <Typography variant="subtitle2" color="error">
                          {errorFor('email')}
                        </Typography>
                      )}
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <Field
                            name="information.first"
                            render={({ field }: FieldProps<ConsumerFormData>) => (
                              <TextField
                                type="text"
                                label={Labels.UserInformation('first')}
                                error={!!errorFor('information')}
                                fullWidth
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Field
                            name="information.last"
                            render={({ field }: FieldProps<ConsumerFormData>) => (
                              <TextField
                                type="text"
                                label={Labels.UserInformation('last')}
                                error={!!errorFor('information')}
                                fullWidth
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Field
                            name="information.timezone"
                            render={({ field }: FieldProps<ConsumerFormData>) => (
                              <TextField
                                type="text"
                                label={Labels.UserInformation('timezone')}
                                error={!!errorFor('information')}
                                fullWidth
                                {...field}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Card>
                            <CardHeader title={Labels.UserInformation('address')} />
                            <CardContent>
                              <Grid container spacing={3}>
                                <Grid item xs={3}>
                                  <Field
                                    name="information.address.address"
                                    render={({ field }: FieldProps<ConsumerFormData>) => (
                                      <TextField
                                        type="text"
                                        label={Labels.UserAddress('address')}
                                        error={!!errorFor('information')}
                                        fullWidth
                                        {...field}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={1}>
                                  <Field
                                    name="information.address.code"
                                    render={({ field }: FieldProps<ConsumerFormData>) => (
                                      <TextField
                                        type="text"
                                        label={Labels.UserAddress('code')}
                                        error={!!errorFor('information')}
                                        fullWidth
                                        {...field}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <Field
                                    name="information.address.country"
                                    render={({ field }: FieldProps<ConsumerFormData>) => (
                                      <TextField
                                        type="text"
                                        label={Labels.UserAddress('country')}
                                        error={!!errorFor('information')}
                                        fullWidth
                                        {...field}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={4}>
                                  <Field
                                    name="information.address.locality"
                                    render={({ field }: FieldProps<ConsumerFormData>) => (
                                      <TextField
                                        type="text"
                                        label={Labels.UserAddress('locality')}
                                        error={!!errorFor('information')}
                                        fullWidth
                                        {...field}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <Field
                                    name="information.address.region"
                                    render={({ field }: FieldProps<ConsumerFormData>) => (
                                      <TextField
                                        type="text"
                                        label={Labels.UserAddress('region')}
                                        error={!!errorFor('information')}
                                        fullWidth
                                        {...field}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardHeader
                      title={Labels.Consumer('metadata')}
                      subheader={errorFor('metadata')}
                    />
                    <CardContent>
                      {errorFor('email') && (
                        <Typography variant="subtitle2" color="error">
                          {errorFor('email')}
                        </Typography>
                      )}
                      {Object.keys(formikBag.values.metadata).map(mkey => (
                        <Grid container spacing={3} key={mkey}>
                          <Grid item xs={4}>
                            <TextField disabled value={mkey} fullWidth />
                          </Grid>
                          <Grid item xs={7}>
                            <Field
                              name={`metadata.${mkey}`}
                              render={({ field }: FieldProps<ConsumerFormData>) => (
                                <TextField fullWidth {...field} />
                              )}
                            />
                          </Grid>
                          <Grid item xs={1}>
                            <IconButton
                              size="medium"
                              onClick={() => removeMetadataKey(formikBag, mkey)}
                            >
                              <DeleteIcon fontSize="inherit" />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <Field
                            name="newMetadataKey"
                            render={({ field }: FieldProps<ConsumerFormData>) => (
                              <TextField fullWidth {...field} />
                            )}
                          />
                        </Grid>
                        <Grid item xs={7}>
                          <Field
                            name="newMetadataValue"
                            render={({ field }: FieldProps<ConsumerFormData>) => (
                              <TextField fullWidth {...field} />
                            )}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton size="medium" onClick={() => addMetadataKey(formikBag)}>
                            <SaveIcon fontSize="inherit" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={!formikBag.isValid}
              >
                Save
              </Button>
            </CardActions>
          </Card>
        </form>
      )}
    </Formik>
  )
}
