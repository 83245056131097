export const fmtDate = (apiDate: Date | undefined) => {
  if (apiDate === undefined) {
    return ''
  }
  const d = new Date(apiDate)
  // const options = new Intl.DateTimeFormat()
  return d.getFullYear() === 0
    ? ''
    : d.toLocaleDateString('en-US', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      })
}
