import React, { useReducer, createContext } from 'react'
import { CookiesProvider } from 'react-cookie'
import { AuthApi } from '../api/client/api'
import { JarvisUserInfo } from '../api/client'

type AuthStoreState = {
  status: 'initial' | 'authenticated' | 'error'
  authenticated: boolean
  userInfo: JarvisUserInfo
  requestOptions: () => object
  api: AuthApi
  error?: string
}

export type AuthStoreAction =
  | { type: 'success'; userInfo: JarvisUserInfo }
  | { type: 'logout' }
  | { type: 'error'; error: string }

const reducer = (state: AuthStoreState, action: AuthStoreAction): AuthStoreState => {
  switch (action.type) {
    case 'success':
      return { ...state, status: 'authenticated', authenticated: true, userInfo: action.userInfo }
    case 'logout':
      return {
        ...state,
        status: 'initial',
        error: undefined,
        authenticated: false,
        userInfo: {},
      }
    case 'error':
      return {
        ...state,
        status: 'error',
        error: action.error,
        authenticated: false,
        userInfo: {},
      }
    default:
      throw new Error()
  }
}

export type AuthStoreDispatch = {
  state: AuthStoreState
  dispatch: (action: AuthStoreAction) => void
}

const AuthStoreContext = createContext({} as AuthStoreDispatch)

const AuthStoreProvider = ({
  baseUrl,
  children,
}: {
  baseUrl: string
  children: React.ReactNode[] | React.ReactNode | Element
}) => {
  const [state, dispatch] = useReducer(reducer, {
    status: 'initial',
    requestOptions: () => ({
      headers: {
        'Content-Type': 'application/json',
      },
    }),
    api: new AuthApi({ basePath: baseUrl }),
    userInfo: {},
    authenticated: false,
  })
  return (
    <CookiesProvider>
      <AuthStoreContext.Provider value={{ state, dispatch }}>{children}</AuthStoreContext.Provider>
    </CookiesProvider>
  )
}

export { AuthStoreContext, AuthStoreProvider }
