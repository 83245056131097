import { JarvisConsumer } from '../../api/client'

export default (field: keyof JarvisConsumer): string =>
  ({
    uuid: 'Consumer UUID',
    email: 'Consumer email',
    information: 'User information',
    metadata: 'Metadata',
    phone: 'Phone',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
  }[field])
