import React, { useReducer, createContext } from 'react'
import { JarvisConsumer, ConsumersApi } from '../api/client/api'

type ConsumersStoreState = {
  status: string
  api: ConsumersApi
  consumers: JarvisConsumer[]
  total: number
  error?: Error
}

type ConsumersStoreAction =
  | { type: 'success'; consumers: JarvisConsumer[]; total: number }
  | { type: 'error'; error: Error }

const reducer = (state: ConsumersStoreState, action: ConsumersStoreAction): ConsumersStoreState => {
  switch (action.type) {
    case 'success':
      return { ...state, status: 'success', consumers: action.consumers, total: action.total }
    case 'error':
      return { ...state, status: 'error', consumers: [], error: action.error }
    default:
      throw new Error()
  }
}

export type ConsumersStoreDispatch = {
  state: ConsumersStoreState
  dispatch: (action: ConsumersStoreAction) => void
}

const ConsumersStoreContext = createContext({} as ConsumersStoreDispatch)

const ConsumersStoreProvider = ({
  baseUrl,
  children,
}: {
  baseUrl: string
  children: React.ReactNode[] | React.ReactNode | Element
}) => {
  const [state, dispatch] = useReducer(reducer, {
    status: 'initial',
    api: new ConsumersApi({ basePath: baseUrl }),
    consumers: [],
    total: 0,
  })
  return (
    <ConsumersStoreContext.Provider value={{ state, dispatch }}>
      {children}
    </ConsumersStoreContext.Provider>
  )
}

export { ConsumersStoreContext, ConsumersStoreProvider }
