import React, { useContext, useEffect, useState } from 'react'
import { Typography, Grid } from '@material-ui/core'
import { withRouter } from 'react-router'
import { UserDrawer } from '../../../components/layout'
import { AlertsContext, AuthStoreContext, UsersStoreContext } from '../../../state'
import { JarvisUser, JarvisUpdateSuperuserRequest } from '../../../api/client'
import path_for from '../../path_for'
import { handleError } from '../../../utils'
import { MembershipForm } from './form'
import { JarvisError } from '../../../utils/handle_error'

export default withRouter(({ history, match }) => {
  const { dispatch: showAlert } = useContext(AlertsContext)
  const { state: session, dispatch: auth } = useContext(AuthStoreContext)
  const { state: usersStore } = useContext(UsersStoreContext)

  const [user, setUser] = useState<JarvisUser | null>(null)
  const [error, setError] = useState<JarvisError | null>(null)

  useEffect(() => {
    if (!match.params.uuid || !match.params.userId) {
      return
    }
    usersStore.api
      .get(match.params.userId, session.requestOptions())
      .then(response => {
        if (response.consumerUuid !== match.params.uuid) {
          showAlert({ type: 'error', message: 'membership not found' })
          history.push(path_for.Memberships(match.params.uuid))
          return
        }
        setUser(response)
      })
      .catch(err => {
        setUser(null)
        handleError(err, showAlert, auth)
      })
    return
  }, [
    usersStore.api,
    setUser,
    showAlert,
    auth,
    session,
    history,
    match.params.uuid,
    match.params.userId,
  ])

  const handleSubmit = (values: JarvisUpdateSuperuserRequest) => {
    if (user) {
      usersStore.api
        .updateSuperuser(
          {
            ...values,
            tenantId: user.tenant && user.tenant.id!,
            userId: user.id!,
            consumerUuid: user.consumerUuid!,
          },
          session.requestOptions()
        )
        .then(resp => {
          showAlert({
            type: 'success',
            message: `Superuser membership was successfully updated.`,
          })
          history.push(path_for.Memberships(user.consumerUuid!))
        })
        .catch(err => handleError(err, showAlert, auth).then(je => setError(je)))
    }
  }

  return (
    <UserDrawer
      breadcrumbs={[
        ['Jarvis', path_for.Home()],
        ['Users', path_for.Users()],
        ['Memberships', path_for.Memberships(match.params.uuid)],
        'Edit',
      ]}
    >
      {user && user.tenant && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">
              Update membership for {user.email} in tenant {user.tenant.name}
            </Typography>
            <MembershipForm
              form={{
                tenantId: user.tenant.id!,
                consumerUuid: user.consumerUuid!,
                isRoot: user.isRoot!,
                roleId: user.role!.id!,
                folderIds: user.folders!.map(f => f.id!),
              }}
              onSubmit={handleSubmit}
              error={error}
            />
          </Grid>
        </Grid>
      )}
    </UserDrawer>
  )
})
