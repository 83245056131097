import React, { useContext, useEffect, useState } from 'react'
import {
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Grid,
  FormControlLabel,
  Checkbox,
  Box,
  Button,
  Card,
  CardContent,
} from '@material-ui/core'
import {
  AlertsContext,
  AuthStoreContext,
  RolesStoreContext,
  FoldersStoreContext,
} from '../../../state'
import { JarvisRole, FilterOperator, JarvisFolder, JarvisErrorDetail } from '../../../api/client'
import { Formik, FormikProps, Field, FieldProps } from 'formik'
import TransferList from '../../../components/lists/transfer_list'
import { handleError } from '../../../utils'
import { JarvisError } from '../../../utils/handle_error'

export type MembershipFormData = {
  tenantId: string
  consumerUuid: string
  isRoot: boolean
  roleId: string
  folderIds: Array<string>
}

const MembershipForm = ({
  form,
  onSubmit,
  error,
}: {
  form: MembershipFormData
  onSubmit: (values: MembershipFormData) => void
  error: JarvisError | null
}) => {
  const { dispatch: showAlert } = useContext(AlertsContext)
  const { state: session, dispatch: auth } = useContext(AuthStoreContext)
  const { state: foldersStore } = useContext(FoldersStoreContext)
  const { state: rolesStore } = useContext(RolesStoreContext)

  const [isRolesLoading, setIsRolesLoading] = useState(true)
  const [isFoldersLoading, setIsFoldersLoading] = useState(true)
  const [roles, setRoles] = useState<JarvisRole[]>([])
  const [folders, setFolders] = useState<JarvisFolder[]>([])

  const isLoading = isRolesLoading && isFoldersLoading

  useEffect(() => {
    setIsRolesLoading(true)
    rolesStore.api
      .list(
        {
          filter: {
            byColumn: {
              column: 'r.tid',
              operator: FilterOperator.EXACT,
              value: form.tenantId,
            },
          },
          orderBy: [{ name: 'r.name' }, { name: 'r.id' }],
        },
        session.requestOptions()
      )
      .then(response => {
        setIsRolesLoading(false)
        setRoles(response.roles!)
      })
      .catch(err => {
        setIsRolesLoading(false)
        setRoles([])
        handleError(err, showAlert, auth)
      })
  }, [rolesStore.api, form.tenantId, showAlert, auth, session])

  useEffect(() => {
    setIsFoldersLoading(true)
    foldersStore.api
      .list(
        {
          filter: {
            byColumn: {
              column: 'tid',
              operator: FilterOperator.EXACT,
              value: form.tenantId,
            },
          },
          orderBy: [{ name: 'name' }, { name: 'id' }],
        },
        session.requestOptions()
      )
      .then(response => {
        setIsFoldersLoading(false)
        setFolders(response.folders!)
      })
      .catch(err => {
        setIsFoldersLoading(false)
        setFolders([])
        handleError(err, showAlert, auth)
      })
  }, [foldersStore.api, form.tenantId, showAlert, auth, session])

  const errorDetailsFor = (field: string): JarvisErrorDetail[] => (error ? error.details : [])

  return (
    <>
      {!isLoading && (
        <Formik initialValues={form} onSubmit={onSubmit}>
          {(formikBag: FormikProps<MembershipFormData>) => (
            <form onSubmit={formikBag.handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Paper>
                    <Box p={2}>
                      <Typography variant="h6">Root privileges</Typography>
                      <Field
                        name="isRoot"
                        render={({ field }: FieldProps<MembershipFormData>) => (
                          <FormControlLabel
                            control={<Checkbox {...field} checked={field.value} />}
                            label="Is root?"
                          />
                        )}
                      />
                    </Box>
                  </Paper>
                  <Box m={2}></Box>
                  <Field
                    name="roleId"
                    render={({ field }: FieldProps<MembershipFormData>) => (
                      <Paper>
                        <Box p={2}>
                          <Typography variant="h6">Role</Typography>
                          <List dense>
                            {roles.map(role => (
                              <ListItem
                                button
                                key={role.id}
                                selected={field.value === role.id}
                                onClick={() =>
                                  formikBag.setValues({ ...formikBag.values, roleId: role.id! })
                                }
                              >
                                <ListItemText>{role.name}</ListItemText>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      </Paper>
                    )}
                  />
                </Grid>
                <Grid item xs={9}>
                  <Card>
                    <CardContent>
                      <Box p={2}>
                        <Typography variant="h6">Folders</Typography>
                        {errorDetailsFor('folder_ids').map(e => (
                          <Typography color="error" key={e.msg! + e.field!}>
                            {e.msg}
                          </Typography>
                        ))}
                        <Field
                          name="folderIds"
                          render={({ field }: FieldProps<MembershipFormData>) => (
                            <TransferList
                              data={new Map(folders.map(f => [f.id!, f.name!] as [string, string]))}
                              initial={field.value}
                              onChange={selected => formikBag.setFieldValue('folderIds', selected)}
                            ></TransferList>
                          )}
                        />
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Paper>
                    <Box p={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!formikBag.dirty}
                      >
                        Save
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      )}
    </>
  )
}

export { MembershipForm }
