import React, { useContext, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { AlertsContext, AuthStoreContext } from '../../state'
import { handleError } from '../../utils'
import path_for from '../path_for'
import { Box } from '@material-ui/core'

export default withRouter(({ history, match }) => {
  const { dispatch: showAlert } = useContext(AlertsContext)
  const { state: session, dispatch: auth } = useContext(AuthStoreContext)

  useEffect(() => {
    if (session.authenticated) {
      session.api
        .logout()
        .then(_ => {
          auth({ type: 'logout' })
          showAlert({
            type: 'success',
            message: `You have successfully logged out.`,
          })
        })
        .catch(err => {
          handleError(err).then(je => {
            auth({ type: 'error', error: je.message })
            history.push(path_for.Home())
          })
        })
    }
  }, [showAlert, auth, history, session.api, session.authenticated])

  return <Box p={10}>Thank you for visiting Jarvis.</Box>
})
