const Home = () => `/`

const Auth = (provider: string) => `/auth/${provider}`
const Logout = () => `/logout`

const Tenants = (id?: string) => (id ? `/tenants/${id}` : `/tenants`)
const NewTenant = () => `/tenants/new`

const Users = () => '/users'
const UpgradeUser = (userId: string) => `/users/${userId}/upgrade`
const DowngradeUser = (userId: string) => `/users/${userId}/downgrade`

const EditConsumers = (consumerUuid: string) => `/consumers/${consumerUuid}/edit`

const Memberships = (consumerUuid: string, userId?: string) =>
  userId === undefined
    ? `/consumers/${consumerUuid}/memberships`
    : `/consumers/${consumerUuid}/memberships/${userId}`
const NewMembership = (consumerUuid: string) => `/consumers/${consumerUuid}/memberships/new`
const EditMembership = (consumerUuid: string, userId: string) => Memberships(consumerUuid, userId)

export default {
  Home,
  Auth,
  Logout,

  Tenants,
  NewTenant,

  Users,
  UpgradeUser,
  DowngradeUser,

  EditConsumers,

  Memberships,
  NewMembership,
  EditMembership,
}
