import React, { useContext, useEffect, useState } from 'react'
import { RouteProps, RouteComponentProps, Route } from 'react-router'
import { AuthStoreContext } from '../state'
import { handleError } from '../utils'
import { JarvisError } from '../utils/handle_error'
import { Typography, Box, CircularProgress } from '@material-ui/core'
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied'

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
}

const pageBeforeLoginKey = 'pageBeforeLogin'

export const pageBeforeLogin = () => localStorage.getItem(pageBeforeLoginKey)
export const setPageBeforeLogin = (page: string) => localStorage.setItem(pageBeforeLoginKey, page)

// Show private content if authentication succeed. Otherwise shows an error page.
const PrivateRoute = ({ component, ...rest }: PrivateRouteProps) => {
  type AuthState = 'initial' | 'loginRequired' | 'authenticated' | 'error'

  const { state: session, dispatch: authenticate } = useContext(AuthStoreContext)
  const [authState, setAuthState] = useState<AuthState>('initial')
  const [error, setError] = useState<JarvisError>({ message: 'unknow error', details: [] })

  const processingPage: React.FunctionComponent<{}> = () => {
    return (
      <Box p={10}>
        <CircularProgress />
      </Box>
    )
  }

  const [content, setContent] = useState<typeof component>(processingPage)

  const errorPage: React.FunctionComponent<{}> = () => {
    const title = () =>
      error.message === 'TypeError: Failed to fetch'
        ? 'It seems that backend server is down.'
        : "It seems that you don't have required permissions."
    return (
      <Box p={10}>
        <Typography variant="h5" color="error">
          {title()}
        </Typography>
        <SentimentDissatisfiedIcon />
        <Typography variant="body1">{error.message}</Typography>
      </Box>
    )
  }

  // Start action based on current authState
  useEffect(() => {
    switch (authState) {
      case 'initial':
        if (session.authenticated) {
          setAuthState('authenticated')
          return
        }

        // Try to get userInfo using existing cookie
        session.api
          .me(session.requestOptions())
          .then(userInfo => {
            authenticate({ type: 'success', userInfo: userInfo })
            setAuthState('authenticated')
          })
          .catch(() => {
            setAuthState('loginRequired')
          })
        return

      case 'loginRequired':
        const tokenError =
          session.error &&
          (session.error.includes('not valid token') || session.error.includes('bad token'))

        if (session.status === 'error' && !tokenError) {
          console.log('Session error', session.error)
          setError({ message: session.error || 'unknown session error', details: [] })
          setAuthState('error')
          return
        }

        setPageBeforeLogin(
          [document.location.pathname, document.location.search].filter(p => p !== '').join('/')
        )
        session.api
          .loginURL('Google')
          .then(resp => {
            window.location.assign(resp.loginURL!)
          })
          .catch(err => {
            handleError(err).then(je => {
              setError(je)
              setAuthState('error')
            })
          })
        return

      case 'authenticated':
        if (session.status !== 'authenticated') {
          setAuthState('initial')
          return
        }
        break

      default:
        break
    }
  }, [authState, component, session.status, session.error, authenticate, session])

  // Show content based on current authState
  useEffect(() => {
    switch (authState) {
      case 'loginRequired':
        setContent(processingPage)
        break

      case 'authenticated':
        setContent(component)
        break

      case 'error':
        setContent(errorPage)
        break

      default:
        break
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [component, authState, setContent])

  return <Route {...rest}>{content}</Route>
}

export default PrivateRoute
