import React, { useReducer, createContext } from 'react'
import { JarvisTenant, TenantsApi } from '../api/client/api'

type TenantsStoreState = {
  status: string
  api: TenantsApi
  tenants: JarvisTenant[]
  total: number
  error?: Error
}

type TenantsStoreAction =
  | { type: 'success'; tenants: JarvisTenant[]; total: number }
  | { type: 'error'; error: Error }

const reducer = (state: TenantsStoreState, action: TenantsStoreAction): TenantsStoreState => {
  switch (action.type) {
    case 'success':
      return { ...state, status: 'success', tenants: action.tenants, total: action.total }
    case 'error':
      return { ...state, status: 'error', tenants: [], error: action.error }
    default:
      throw new Error()
  }
}

export type TenantsStoreDispatch = {
  state: TenantsStoreState
  dispatch: (action: TenantsStoreAction) => void
}

const TenantsStoreContext = createContext({} as TenantsStoreDispatch)

const TenantsStoreProvider = ({
  baseUrl,
  children,
}: {
  baseUrl: string
  children: React.ReactNode[] | React.ReactNode | Element
}) => {
  const [state, dispatch] = useReducer(reducer, {
    status: 'initial',
    api: new TenantsApi({ basePath: baseUrl }),
    tenants: [],
    total: 0,
  })
  return (
    <TenantsStoreContext.Provider value={{ state, dispatch }}>
      {children}
    </TenantsStoreContext.Provider>
  )
}

export { TenantsStoreContext, TenantsStoreProvider }
