import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import SuperuserIcon from '@material-ui/icons/SupervisedUserCircle'
import RootUserIcon from '@material-ui/icons/HowToReg'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { JarvisUser } from '../../api/client'
import { Typography, TablePagination, IconButton } from '@material-ui/core'
import { fmtDate } from '../../utils'
import Skeleton from '@material-ui/lab/Skeleton'
import { PageState, AvailableRowsPerPage } from '../../common'
import { useStyles } from './users.styles'

type HandleClickFn = (e: React.MouseEvent<any>, user: JarvisUser) => void

export default function UsersTable({
  rows,
  pageState,
  setPageState,
  total,
  selectedUser,
  onClick,
  isLoading,
  onMoreClick,
}: {
  rows: JarvisUser[]
  pageState: PageState
  setPageState?: React.Dispatch<React.SetStateAction<PageState>>
  total: number
  selectedUser?: JarvisUser
  onClick?: HandleClickFn
  isLoading: boolean
  onMoreClick?: HandleClickFn
}) {
  const classes = useStyles()

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageState && setPageState({ ...pageState, pageNumber: newPage })
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageState && setPageState({ ...pageState, pageSize: +event.target.value })
  }

  return (
    <Paper className={classes.root}>
      <div>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>id</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="center">Superuser?</TableCell>
              <TableCell align="center">Tenant</TableCell>
              <TableCell align="center">Root?</TableCell>
              <TableCell align="center">Role</TableCell>
              <TableCell align="center">Created</TableCell>
              <TableCell align="center">Updated</TableCell>
              <TableCell align="center">Last used</TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading
              ? Array.from(Array(pageState.pageSize).keys()).map(i => (
                  <TableRow key={i}>
                    <TableCell colSpan={10}>
                      <Skeleton variant="rect" height={40} />
                    </TableCell>
                  </TableRow>
                ))
              : rows.map(row => (
                  <TableRow
                    key={row.id}
                    selected={row === selectedUser}
                    onClick={e => onClick && onClick(e, row)}
                  >
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="right">{row.email}</TableCell>
                    <TableCell align="center">{row.isSuperuser && <SuperuserIcon />}</TableCell>
                    <TableCell align="center">{row.tenant!.name}</TableCell>
                    <TableCell align="center">{row.isRoot && <RootUserIcon />}</TableCell>
                    <TableCell align="center">{row.role!.name}</TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" noWrap>
                        {' '}
                        {fmtDate(row.createdAt)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" noWrap>
                        {' '}
                        {fmtDate(row.updatedAt)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      <Typography variant="body2" noWrap>
                        {' '}
                        {fmtDate(row.lastUsedAt)}
                      </Typography>
                    </TableCell>
                    <TableCell align="center">
                      {onMoreClick && (
                        <IconButton
                          aria-label="more"
                          aria-haspopup="true"
                          onClick={e => onMoreClick(e, row)}
                        >
                          <MoreVertIcon fontSize="small" />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
          </TableBody>
        </Table>
      </div>
      {setPageState && (
        <TablePagination
          rowsPerPageOptions={AvailableRowsPerPage}
          component="div"
          count={total}
          rowsPerPage={pageState.pageSize}
          page={pageState.pageNumber}
          backIconButtonProps={{
            'aria-label': 'previous page',
          }}
          nextIconButtonProps={{
            'aria-label': 'next page',
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  )
}
